	<!--- Start Header --->
	<div class="modal-header">
		<h3 class="modal-title"> {{title}} <i (click)="cancel()" class="fa fa-times pull-right"></i>
		<i class="fa pull-right" [ngClass]="{'fa-plus-square-o': minimise, 'fa-minus-square-o': !minimise}" style= "margin-right: 10px" (click)="minimise=!minimise"></i>
		</h3>
	</div>
	<!--- End Header --->
	<hr>
	<!--- Start Body-->
	<div class="panel-body"> 
		<div  class="container-border" style="padding: 10px;">
			<textarea 
				name="task_desc" 
				class="form-control input-sm" rows="3" cols="40"
				[(ngModel)]="rejectMessage" required>
			</textarea>
		</div> 
	</div>
	<!--- End  Body --->

	<!--- Start  Footer --->
	<div class="modal-footer" *ngIf="!loading" uib-collapse="minimise">
		<button class="btn btn-info btn-sm" type="button" (click)="rejectRequest()">Reject</button>

		<button class="btn btn-warning btn-sm" type="button"  (click)="cancel()">Cancel</button>
	</div>
	<!--- End  Footer --->