
<div class="container-fluid gt-user-details">
    <!-- <div class="row" style="margin-top: 20px;">
        <div class="col-md-12">
            <div class="col-md-4">
            
                <div class="user-details">
                    <div class="user-label">Client : </div>
                    <div class="user-value">
                        <mat-form-field style="line-height: 2.25 !important; width:96% !important; margin-top: 6px;">
                            <input type="text" placeholder="Type Client Name" [formControl]="clientSelected"
                                (ngModelChange)="getClient($event)" matInput [matAutocomplete]="auto">
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let option of filteredOptions" [value]="option.clientName"
                                    (click)="addClient(option)">
                                    {{option.clientName}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </div>
                
            </div>
    
            <div class="col-md-3" style="">
                
                <div class="user-details">
                    <div class="user-label">Roles : </div>
                    <div class="user-value">
                        <select required class="input-sm" name="user_type" (change)="isDefaultClientAllowed()"
                            [(ngModel)]="user.user_type" style="height: 23px; padding: 0px; border: 1px solid #ccc">
                            <option *ngFor="let s of user_types" [value]="s.label">{{ s.label }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="col-md-2">
                <button class="btn btn-success btn-sm" type="button" (click)="addSelection()">Add Selection</button>
            </div>
        </div>
    </div> -->

    <div class="row" style="">
        <div class="col-md-8 offset-md-2 userNotifBlock" *ngIf="clientList.length == 0">
            <span class="fa fa-exclamation-circle" > &nbsp; Please add Access Requests in the below table by making appropriate selections.</span>
        </div>
        <div class="col-md-12">
            <div class="panel panel-default">
                <div class="panel-heading" style="padding-left: 5px; font-weight: 700;">Client Access Requests <span class="fa fa-refresh refreshIcon" (click)="getData()"></span></div>
                <div class="panel-body" style="padding: 2px; height: 500px;overflow-y: auto;overflow-x: hidden;">
                    <!-- <div class="userNotifBlock" *ngIf="clientList.length == 0">
                        <span>Please add Access Requests from the above selection.</span>
                    </div> -->
                    <table class="gt-client table table-bordered table-striped table-condensed"
                        style="margin-bottom: 0px">
                        <thead>
                            <tr>
                                <!-- <th style="">Requested By</th> -->
                                <th style="">Client</th>
                                <th style="">Role</th>
                                <th>Approver</th>
                                <th>Action</th>
                                <th>Requested Date</th>
                                <th>Approved Date</th>
                            </tr>
                        </thead>
                        <tr>
                            <!-- <td>
                                {{user_name}}
                            </td> -->
                            <td style="padding-bottom: 0px;">
                                <div class="user-value">
                                    <mat-form-field style="line-height: 23px !important;
                                    width: 96% !important;
                                    font-size: 12px;">
                                        <input type="text" placeholder="Type Client Name" [formControl]="clientSelected"
                                            (ngModelChange)="getClient($event)" matInput [matAutocomplete]="auto">
                                        <mat-autocomplete #auto="matAutocomplete">
                                            <mat-option *ngFor="let option of filteredOptions" [value]="option.CLIENT_NAME"
                                                (click)="addClient(option)">
                                                {{option.CLIENT_NAME}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                            </td>
                            <td>
                                <div class="user-value">
                                    <select required class="input-sm" name="user_type" (change)="isClientRoleSelected()"
                                        [(ngModel)]="user.user_type" style=" padding: 0px; border: 1px solid #ccc">
                                        <option *ngFor="let s of user_types" [value]="s.label">{{ s.label }}</option>
                                    </select>
                                </div>
                            </td>
                            <td>
                            </td>
                            <td>
                                <div class="col-md-12" style="">
                                    <button class="btn btn-primary btn-sm" type="button" *ngIf="sendRequestBtn" (click)="requestApproval()">Request Approval</button>
                                </div>
                            </td>
                            <td>

                            </td>
                            <td>

                            </td>
                            <!-- <td>
                                <div style="text-align: center;">
                                    <span class="fa fa-plus-circle color-green" style="cursor: pointer;" (click)="addSelection()"></span>
                                </div>
                            </td> -->
                        </tr>
                        <tr *ngFor="let item of pendingRequests; let i = index"
                            [ngClass]="{'active-client': item.active && item.user_type_key !== null}">
                            <!-- <td>
                                {{user_name}}
                            </td> -->
                            <td>{{ item.CLIENT_NAME }}</td>
                            <td>
                                {{item.USER_TYPE}}
                            </td>
                            <td>
                                {{item.APPROVER_NAME}}
                            </td>
                            <td>
                                <div class="col-md-12" style="">
                                    <button *ngIf="item.REQUEST_STATUS == 'Created'" class="btn btn-primary btn-sm" type="button" (click)="sendReminderMail(item)">Send Reminder</button>
                                    <span *ngIf="item.REQUEST_STATUS !== 'Created'">{{item.REQUEST_STATUS}}</span>
                                </div>
                            </td>
                            <td>
                                <!-- <div style="text-align: center;">
                                    <span class="fa fa-minus-circle color-red" style="cursor: pointer;" (click)="removeSelection(i)"></span>
                                </div> -->
                                {{item.CREATED_ON}}
                            </td>
                            <td>
                                {{item.UPDATED_ON}}
                            </td>
                        </tr>
                        <tr *ngFor="let item of clientList; let i = index"
                            [ngClass]="{'active-client': item.active && item.user_type_key !== null}">
                            <td>
                                {{user_name}}
                            </td>
                            <td>{{ item.client_name }}</td>
                            <td>
                                {{item.user_type_key}}
                            </td>
                            <td>
                                <!-- Approver Name -->
                            </td>
                            <td>
                                <!-- <div class="col-md-12" style="">
                                    <button class="btn btn-primary btn-sm" type="button" (click)="requestApproval([item])">Request Approval</button>
                                </div> -->
                            </td>
                            <!-- <td>
                                <div style="text-align: center;">
                                    <span class="fa fa-minus-circle color-red" style="cursor: pointer;" (click)="removeSelection(i)"></span>
                                </div>
                            </td> -->
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="row" *ngIf="clientList.length > 0">
        <div class="col-md-12" style="text-align: right">
            <button class="btn btn-warning btn-sm" type="button" (click)="requestApproval(clientList)">Send Request</button>
        </div>
    </div> -->
</div>