import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'gtw-users-component',
  templateUrl: './users-component.component.html',
  styleUrls: ['./users-component.component.scss']
})
export class UsersComponentComponent implements OnInit {

  title: string = '';
  rowData: any;
  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog) {
    this.rowData = data.rowData;
  }

  ngOnInit(): void {
  }

  cancel(): void {
    this.dialog.closeAll();

  }

  eventType(type: string): void {
    if(type == 'cancel') {
      this.cancel();
    } else {
      this.title = type;
    }
  }

}
