import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import _ from 'lodash';
//import { GlobalService } from './global.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
//import { WorkspaceService } from '../../workspace/workspace.service';
//import { any } from '@uirouter/core';
import { ActionResponse } from '../../shared/_models/action-response.model';

@Injectable({
  providedIn: 'root',
})
export class JsonObjectFactoryService {
  constructor(
    private http: HttpClient,
    //private GlobalService: GlobalService,
    private _snackBar: MatSnackBar,
    //private workspaceFactory: WorkspaceService
  ) {}
  private checkLockedbj: Array<any> = [];
  durationInSeconds = 4;

  /* save JSON  */
  saveJSON(_url: string, _data: any, _globalService: any) {
    let filterParams = _globalService.workspaceFactory.activeScreen
      .filters
      ? _globalService.workspaceFactory.activeScreen.filters.getFilterParams()
      : {};
    console.log('___data', _data);
    let rowData =
      _globalService.workspaceFactory.activeScreen.data &&
      _globalService.workspaceFactory.activeScreen.data.selected &&
      _globalService.workspaceFactory.activeScreen.data.selected.rowData;
    if (_.isArray(rowData) && rowData.length > 0) {
      _.each(rowData, (item: any) => {
        this.checkLockedbj.push({
          leid: item.data.LEID || item.data.SOURCE_LEID, //"IF1139",
          me_code:
            item.data.ME_CODE || item.data.ME || item.data.SOURCE_ME_CODE,
          cdr_number:
            //need to check item.data.CDR_NO || rowData.TAX_CODE || item.data.SOURCE_CDR_NO,
            item.data.CDR_NO || item.data.TAX_CODE || item.data.SOURCE_CDR_NO,
          consol_group_key:
            item.data.CONSOL_GROUP_KEY || item.data.FILING_GROUP, //1,
          reporting_period:
            item.data.REPORTING_PERIOD || item.data.SOURCE_REPORTING_PERIOD,
          combination_key:
            item.data.COMBINATION_KEY ||
            item.data.COMPL_COMBINATION_KEY ||
            item.data.HO_COMBINATION_KEY, //1,
          scenario_key: item.data.SCENARIO_KEY
            ? item.data.SCENARIO_KEY
            : _data.scenario
            ? _data.scenario
            : filterParams.scenario,
        });
      });
    } else if (rowData) {
      this.checkLockedbj.push({
        leid: rowData.LEID || rowData.SOURCE_LEID, //"IF1139",
        me_code: rowData.ME_CODE || rowData.ME || rowData.SOURCE_ME_CODE,
        cdr_number: rowData.CDR_NO || rowData.TAX_CODE || rowData.SOURCE_CDR_NO,
        consol_group_key: rowData.CONSOL_GROUP_KEY || rowData.FILING_GROUP, //1,
        reporting_period:
          rowData.REPORTING_PERIOD || rowData.SOURCE_REPORTING_PERIOD,
        combination_key:
          rowData.COMBINATION_KEY ||
          rowData.COMPL_COMBINATION_KEY ||
          rowData.HO_COMBINATION_KEY,
        scenario_key: rowData.SCENARIO_KEY
          ? rowData.SCENARIO_KEY
          : _data.scenario
          ? _data.scenario
          : filterParams.scenario,
      });
    } else {
      this.checkLockedbj.push({
        scenario_key: _data.scenario ? _data.scenario : filterParams.scenario,
      });
    }
    if (
      _globalService.workspaceFactory.activeScreen &&
      (_globalService.workspaceFactory.activeScreen.screen_key ==
        10305 ||
        _globalService.workspaceFactory.activeScreen.screen_key == 10072)
    ) {
      /* these screens have only filing group locking FIR*/
      this.checkLockedbj = [];
      this.checkLockedbj.push({ consol_group_key: rowData.FILING_GROUP });
    }
    _data.tax_year = _data.tax_year ? _data.tax_year : filterParams.tax_year;
    _data.scenario = _data.scenario ? _data.scenario : filterParams.scenario;
    _data.jcd_key = _globalService.globalParams.jcd_key;
    _data.issue_id = _globalService.globalParams.issue_id;
    _data.issue_short_desc =
    _globalService.globalParams.issue_short_desc;
    _data.checkLockedbj = JSON.stringify(this.checkLockedbj);

    let scenarioCode: any;
    if (
      _globalService.workspaceFactory.activeScreen &&
      _globalService.workspaceFactory.activeScreen.filters &&
      typeof _globalService.workspaceFactory.activeScreen.filters
        .getFilters === 'function'
    ) {
      /*  scenarioCode =  workspaceFactory.activeScreen.filters.getFilters().length>1 ?
                         _.filter(workspaceFactory.activeScreen.filters.getFilters(),{param_name:'scenario'})[0].selected : '';//_.filter(workspaceFactory.activeScreen.filters.getFilters(),{param_name:'scenario'})[0].selected;
                         _data.scenarioCode = scenarioCode.CODE; */
      let foundScenario: any = _.find(
        _globalService.workspaceFactory.activeScreen.filters.getFilters(),
        { param_name: 'scenario' }
      );
      if (foundScenario && foundScenario.selected) {
        scenarioCode = foundScenario.selected;
        _data.scenarioCode = scenarioCode.CODE;
        _data.is_issue_key_enabled = scenarioCode.ISSUE_ID_ENABLED;
      }
    } else if (_data.scenario) {
      //let foundScenario = _.find(GlobalService.inputs.tax_year.values,{scenario:_data.scenario});
      let foundScenario: any;
      _.find(_globalService.inputs.tax_year.values, function (i) {
        if (i.value == _data.tax_year) {
          foundScenario = _.find(i.scenarios, { scenario: _data.scenario });
        }
      });
      if (foundScenario && foundScenario.scenario_type_code) {
        _data.scenarioCode = foundScenario.scenario_type_code;
        _data.is_issue_key_enabled = foundScenario.is_issue_key_enabled;
      }
    }
    const data = new FormData();

    _.map(_data, (v: any, k: any) => {
      if (_data[k] !== null && _data[k] !== undefined && _data[k] !== '') {
        data.append(k, v);
      }
    });
    return this.http.post(_url, data).pipe(
      map((response: any) => {
        if (response.callSuccess == '1') {
          return response;
        }
        this.openSnackBar(
          'oops  the requested data could not be delivered. If this continues please contact support.',
          'gtw-snackbar--red'
        );
        throwError(new Error('oops!'));
        return response;
      })
    );
  }

  getJsonObj(
    _url: any,
    _params: any,
    filter: any = undefined,
    grp_key: any = undefined
  ) {
    return this.getJSONObj(_url, _params, filter, grp_key);
  }

  /* getJsonObj */
  getJSONObj(
    _url: any,
    _params: any,
    _globalService: any,
    filter: any = undefined,
    grp_key: any = undefined
  ) {
    let filterParams = _globalService.workspaceFactory.activeScreen
      .filters
      ? _globalService.workspaceFactory.activeScreen.filters.getFilterParams()
      : {};
    let paramsMerge = _.merge({}, filterParams, _params);
    var params: any;
    params = _.merge({}, _globalService.globalParams, paramsMerge);
    delete params.checkLockedbj;
    if (filterParams.tax_year && filterParams.tax_year.length) {
      if (
        (filterParams && filterParams.scenario == undefined) ||
        filterParams.scenario == null
      ) {
        if (_globalService.getScenario(params.tax_year)) {
          params.scenario = _globalService.getScenario(params.tax_year);
        }
      }
      if (grp_key == 25) {
        let params: any = {
          ein: _globalService.getEIN(),
          tax_year: _globalService.getTAXYEAR(),
          entityType: _globalService.getChartType(),
          bsla_key: '',
          reporting_period: '',
        };
        if (filter.refresh_list !== null) {
          var refresh_list = filter.refresh_list.split(',');
          if (refresh_list.length == 1) {
            params.bsla_key = filterParams.BSLA || 0;
          } else if (refresh_list.length == 2) {
            params.reporting_period = filterParams.Reporting_period || 0;
            params.bsla_key = filterParams.BSLA || 0;
          }
        }
        return this.filterApiCall(_url, params);
      } else if (grp_key == 37) {
        let params: any = {
          ein: _globalService.getEIN(),
          tax_year: _globalService.getTAXYEAR(),
          entityType: _globalService.getChartType(),
          leid: '',
          bsla_key: '',
          reporting_period: '',
        };
        if (filter.refresh_list !== null) {
          var refresh_list = filter.refresh_list.split(',');
          if (refresh_list.length == 3) {
            params.bsla_key = filterParams.bsla || 0;
          } else if (refresh_list.length == 4) {
            params.leid = filterParams.LEID || 0;
            params.reporting_period = filterParams.reporting_p || 0;
            params.bsla_key = filterParams.bsla || 0;
          } else if (refresh_list.length == 5) {
            params.reporting_period = filterParams.reporting_p || 0;
            params.bsla_key = filterParams.bsla || 0;
          }
        }
        if (_url.includes('/loadPivotFormJson')) {
          return this.filterPivotFormJson(_url, params);
        }
        if (_url.includes('/loadPivotFormJson')) {
          return this.filterPivotFormJson(_url, params);
        }

        return this.filterApiCall(_url, params);
      }
    }
    if (_url.includes('/loadPivotFormJson')) {
      return this.filterPivotFormJson(_url, params);
    }

    return this.filterApiCall(_url, params);
  }
  filterApiCall(_url: any, params: any) {
    return this.http.get<ActionResponse>(_url, { params }).pipe(
      switchMap((response: ActionResponse) => {
        if (response.callSuccess == '1') {
          return of(response);
        }
        this.openSnackBar(
          'oops  the requested data could not be delivered. If this continues please contact support.',
          'gtw-snackbar--red'
        );
        return throwError('Action call failed!');
      })
    );
  }

  filterPivotFormJson(_url: any, params: any) {
    //irsForm is placeholder for other object name within response
    return this.http.get<any>(_url, { params }).pipe(
      switchMap((response: any) => {
        if (response.irsForm.callSuccess == '1') {
          return of(response);
        }
        this.openSnackBar(
          'oops  the requested data could not be delivered. If this continues please contact support.',
          'gtw-snackbar--red'
        );
        return throwError('Action call failed!');
      })
    );
  }

  /* alert bar */
  openSnackBar(message: string, panelClass: string = '') {
    this._snackBar.open(message, '', {
      duration: this.durationInSeconds * 1000,
      panelClass: [panelClass],
    });
  }

  saveJSONWParams(_url: any, _data: any, _globalService: any) {
    // var defer = $q.defer();

    let filterParams = _globalService.workspaceFactory.activeScreen
      .filters
      ? _globalService.workspaceFactory.activeScreen.filters.getFilterParams()
      : {};
    let paramsMerge = _.merge({}, filterParams, _data);
    let params = _.merge(
      {},
      _globalService.globalParams,
      paramsMerge
    );

    ///////// TEMP HACK /////////////: This will not be needed when scenario is added to filter try; && (params.scenario == ""|| params.scenario == undefined || params.scenario == null)
    if (params.tax_year && params.tax_year.length) {
      params.scenario = _globalService.getScenario(params.tax_year);
    }

    var checkLockedbj = [];
    var rowData =
    _globalService.workspaceFactory.activeScreen.data &&
    _globalService.workspaceFactory.activeScreen.data.selected &&
    _globalService.workspaceFactory.activeScreen.data.selected.rowData;
    if (_.isArray(rowData) && rowData.length > 0) {
      _.each(rowData, function (item) {
        checkLockedbj.push({
          leid: item.data.LEID || item.data.SOURCE_LEID, //"IF1139",
          me_code:
            item.data.ME_CODE || item.data.ME || item.data.SOURCE_ME_CODE,
          cdr_number:
            item.data.CDR_NO || rowData.TAX_CODE || item.data.SOURCE_CDR_NO,
          consol_group_key:
            item.data.CONSOL_GROUP_KEY || item.data.FILING_GROUP, //1,
          reporting_period:
            item.data.REPORTING_PERIOD || item.data.SOURCE_REPORTING_PERIOD,
          combination_key:
            item.data.COMBINATION_KEY ||
            item.data.COMPL_COMBINATION_KEY ||
            item.data.HO_COMBINATION_KEY, //1,
          scenario_key: item.data.SCENARIO_KEY
            ? item.data.SCENARIO_KEY
            : _data.scenario
            ? _data.scenario
            : filterParams.scenario,
        });
      });
    } else if (rowData) {
      checkLockedbj.push({
        leid: rowData.LEID || rowData.SOURCE_LEID, //"IF1139",
        me_code: rowData.ME_CODE || rowData.ME || rowData.SOURCE_ME_CODE,
        cdr_number: rowData.CDR_NO || rowData.TAX_CODE || rowData.SOURCE_CDR_NO,
        consol_group_key: rowData.CONSOL_GROUP_KEY || rowData.FILING_GROUP, //1,
        reporting_period:
          rowData.REPORTING_PERIOD || rowData.SOURCE_REPORTING_PERIOD,
        combination_key:
          rowData.COMBINATION_KEY ||
          rowData.COMPL_COMBINATION_KEY ||
          rowData.HO_COMBINATION_KEY,
        scenario_key: rowData.SCENARIO_KEY
          ? rowData.SCENARIO_KEY
          : _data.scenario
          ? _data.scenario
          : filterParams.scenario,
      });
    } else {
      checkLockedbj.push({
        scenario_key: _data.scenario ? _data.scenario : filterParams.scenario,
      });
    }

    params.checkLockedbj = JSON.stringify(checkLockedbj);

    console.log('params : ', params);

    let scenarioCode: any = '';
    if (
      _globalService.workspaceFactory.activeScreen &&
      _globalService.workspaceFactory.activeScreen.filters &&
      typeof _globalService.workspaceFactory.activeScreen.filters
        .getFilters === 'function'
    ) {
      /*  scenarioCode =  workspaceFactory.activeScreen.filters.getFilters().length>1 ?
                         _.filter(workspaceFactory.activeScreen.filters.getFilters(),{param_name:'scenario'})[0].selected : '';//_.filter(workspaceFactory.activeScreen.filters.getFilters(),{param_name:'scenario'})[0].selected;

                         _data.scenarioCode = scenarioCode.CODE; */
      let foundScenario: any = {};
      foundScenario = _.find(
        _globalService.workspaceFactory.activeScreen.filters.getFilters(),
        { param_name: 'scenario' }
      );
      if (foundScenario && foundScenario.selected) {
        scenarioCode = foundScenario.selected;
        params.scenarioCode = scenarioCode?.CODE;
        params.is_issue_key_enabled = scenarioCode?.ISSUE_ID_ENABLED;
      }
    } else if (params.scenario) {
      let foundScenario: any;
      _.find(_globalService.inputs.tax_year.values, function (i) {
        if (i.value == params.tax_year) {
          foundScenario = _.find(i.scenarios, { scenario: params.scenario });
        }
      });
      if (foundScenario && foundScenario.scenario_type_code) {
        params.scenarioCode = foundScenario.scenario_type_code;
        params.is_issue_key_enabled = foundScenario.is_issue_key_enabled;
      }
    }
    console.log('params2 : ', params);
    const data = new FormData();
    _.map(params, (v: any, k: any) => {
      data.append(k, v);
    });
    console.log('params3 : ', params);
    return this.http.post(_url, data).pipe(
      map((response: any) => {
        if (response.callSuccess == '1') {
          console.log('params 4', params);
          return response;
        }
        this.openSnackBar(
          'oops  the requested data could not be delivered. If this continues please contact support.',
          'gtw-snackbar--red'
        );
        throwError(new Error('oops!'));
        return response;
      })
    );
  }

  arrObjToLower(_data: any) {
    let dataTrans = _.map(_data, (x: any) => {
      let lowerObj = _.transform(x, (result: any, val: any, key: any) => {
        if (key != undefined) {
          result[_.toLower(key)] = val;
        }
      });
      return lowerObj;
    });
    return dataTrans;
  }
  objToLower(_data: any) {
    let lowerObj = _.transform(_data, (result: any, val: any, key: any) => {
      result[key.toLowerCase()] = val;
    });

    return lowerObj;
  }
  arrObjToUpper(_data: any) {
    let dataTrans = _.map(_data, (x: any) => {
      let upperObj = _.transform(x, (result: any, val: any, key: any) => {
        result[key.toUpperCase()] = val;
      });
      return upperObj;
    });
    return dataTrans;
  }
}
