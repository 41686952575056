import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import _ from 'lodash';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { UtilityService } from '../../../shared/_services/utility.service';

@Injectable({
  providedIn: 'root'
})
export class UserAccessApprovalService {
	@Output() emitEvent = new EventEmitter<string>();
    url ={
		getPendingRequests : '/loadJsonObject?action_id=33320',
		approveAccess :'/approve/users/access',
		rejectAccess : '/user/access/request/reject',
    	sendReminder:'/user/access/request/notify',
		FETCH: '/loadJsonObject?action_id=31674',
		GET_APP_KEYS: '/getAppKey',
		GET_SERVER_SETTINGS: '/secureConfigSettings',
	};

	tempParams = {
		"tax_year": 2019,
		"screen_key": "",
		"scenario": 32,
		"jcd_key": "",
		"jcd_ta_key": "",
		"activity_key": "",
		"screen_type": "",
		"is_locked": "N",
	};

  	durationInSeconds= 5;
	rowDataWithTemplates : any;
	rowDataWithBusiness : any;

  	constructor(private httpClient: HttpClient, 
		private http:HttpClient, 
		private _snackBar: MatSnackBar,
		private utilityService: UtilityService ) { 
			this.rowDataWithTemplates = [];
			this.rowDataWithBusiness = {};
		}

	setBusinessAssignData(index: any, rowData: any){
		this.rowDataWithBusiness[index] = rowData;
		this.emitEvent.emit('cancel');
	}

	getBusinessAssignData(index: any){
		return this.rowDataWithBusiness[index];
	}

	setTemplateAssignData(rowData: any){
		this.rowDataWithTemplates.push(rowData);
	}

	getTemplateAssignData(index: any){
		return _.find(this.rowDataWithTemplates, {
			INDEX: index,
		});
	}

	getAllPendingRequests(baseURL:string, user_data: any, type: any){
		//let clientKey = sessionStorage.getItem('client_key') || '';
		const options= {withCredentials:true}
		const url = `${baseURL}${this.url.getPendingRequests}`;

		let body = new FormData();
		body.append('sso_id', user_data.sso_id.toString());
		body.append('client_key', user_data.client_key.toString());
		body.append('request_status', type.toString());
		
		return this.http.post(url, body, options).pipe(
			map((res:any) => { 
				if(res.callSuccess == "1"){
				return res;
				} 
				//this.openSnackBar('Unable to fetch data.','gtw-snackbar--red');
				throwError(new Error('oops!'));
				return null;
			}
		));
	}

	approveUserAccess(baseURL:string, user_data: any, globalData: any, request_data: any, businessData: any){
		//let clientKey = sessionStorage.getItem('client_key') || '';
		const options= {withCredentials:true}
		const url = `${baseURL}${this.url.approveAccess}`;

		let body = new FormData();
		
		body.append('clob_data', JSON.stringify([request_data]));
		body.append('call_type', "pwc_ldap");
		// body.append('tax_year', globalData.globalParams.tax_year.toString());
		// body.append('scenario', globalData.globalParams.scenario.toString());
		// body.append('jcd_key', globalData.globalParams.jcd_key.toString());
		body.append('tax_year', businessData[0].TAX_YEAR.toString());
		body.append('scenario', businessData[0].SCENARIO.toString());
		body.append('jcd_key', businessData[0].JCD_KEY.toString());
		
		return this.http.post(url, body, options).pipe(
			map((res:any) => { 
				//if(res.callSuccess == "1"){
				return res;
				//} 
				//this.openSnackBar('Unable to fetch data.','gtw-snackbar--red');
				throwError(new Error('oops!'));
				return null;
			}
		));
	}

	rejectUserAccess(baseURL:string, user_data: any, globalData: any, request_data: any){
		//let clientKey = sessionStorage.getItem('client_key') || '';
		const options= {withCredentials:true}
		const url = `${baseURL}${this.url.rejectAccess}`;

		let body = new FormData();
		
		//body.append('clob_data', JSON.stringify([request_data]));
		body.append('requester_email', request_data.requester_email.toString());
		body.append('request_key', request_data.request_key.toString());
		body.append('msg', request_data.msg.toString());

		body.append('call_type', "pwc_ldap");
		body.append('tax_year', globalData.globalParams.tax_year.toString());
		body.append('scenario', globalData.globalParams.scenario.toString());
		body.append('jcd_key', globalData.globalParams.jcd_key.toString());
		
		return this.http.post(url, body, options).pipe(
			map((res:any) => { 
				if(res.callSuccess == "1"){
				return res;
				} 
				//this.openSnackBar('Unable to fetch data.','gtw-snackbar--red');
				throwError(new Error('oops!'));
				return null;
			}
		));
	}

	sendReminderMail(baseURL:string, user_data: any, request_data: any){
		//let clientKey = sessionStorage.getItem('client_key') || '';
		const options= {withCredentials:true}
		const url = `${baseURL}${this.url.sendReminder}`;

		let body = new FormData();
		
		body.append('requester_email', user_data.email.toString());
		body.append('request_key', request_data.request_key.toString());
		
		return this.http.post(url, body, options).pipe(
			map((res:any) => { 
				if(res.callSuccess == "1"){
				return res;
				} 
				//this.openSnackBar('Unable to fetch data.','gtw-snackbar--red');
				throwError(new Error('oops!'));
				return null;
			}
		));
	}

	getAllTemplates (client_key: any, base_url: any) {
		let params = this.utilityService.convertToFormData(this.tempParams);
		
		return this.http.get(base_url + this.url.FETCH + '&client_key=' + client_key)
			.pipe(
			map((res:any)=>{
				return res;
			},
		))
	};

	getConfigSettings (_appKey: any, base_url: any){
		return this.http.get(base_url + this.url.GET_SERVER_SETTINGS + '?appKey=' + _appKey)
			.pipe(
			map((res:any)=>{
				return res;
			},
		))
	}

	getAppKeys (clientKey: any, base_url: any){
		return this.http.get(base_url + this.url.GET_APP_KEYS + '?clientKey=' + clientKey)
			.pipe(
			map((res:any)=>{
				return res;
			},
		))
	}
}
