import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
//import { StateService } from '@uirouter/core';
//import { AppService } from 'projects/gtw-elements/src/app/app.service';
//import { GridEventService } from 'projects/gtw-elements/src/app/core/grid/services/grid-event.service';
//import { GlobalService } from 'projects/gtw-elements/src/app/global/services/global.service';
//import { GtwSnackbarService } from 'projects/gtw-elements/src/app/shared/_services/gtw-snackbar.service';
//import { UserAdminService } from '../../users-component/user-admin.service';
import { UserAccessApprovalService } from '../user-access-approval.service';
import { environment } from 'projects/gtw-elements/src/environments/environment';
//import { ExcelService } from './excel.service';
//import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import _ from 'lodash';

@Component({
  selector: 'gtw-view-request-history',
  templateUrl: './view-request-history.component.html',
  styleUrls: ['./view-request-history.component.scss']
})
export class ViewRequestHistoryComponent implements OnInit {

	GENERAL_CONFIG : any;
	pendingRequests: any;
	loading: any;
	minimise: any;
	title: any;
	requestStatus: any;
	selectedStatus: any;
	EXCEL_TYPE = 'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet;charset=UTF-8';
	EXCEL_EXTENSION = '.xlsx';
	globalService: any;

	@Input('header-data')
	set headerData(headerData : any){
		try{
			this.globalService = headerData;
			this.GENERAL_CONFIG = this.globalService.generalConfigData;
			this.init();
		}catch(e){
			console.log("Input Not yet stable. Still waiting to get resolved.");
		}
	}

	constructor(
		//private stateService: StateService,
		//private appService: AppService,
		//private UserAdminFactory: UserAdminService,
		//private AlertService: GtwSnackbarService,
		//private gridEventService: GridEventService,
		private userAccessApprovalService: UserAccessApprovalService
		//private globalService: GlobalService,
		//private dialog: MatDialog,
		//ublic diagRef: MatDialogRef<any>,
		//@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
		//private excelService: ExcelService,
	) { }

	ngOnInit(): void {
		//this.GENERAL_CONFIG = environment;
		this.title = "View History";
		this.requestStatus = [
		{ label: 'Approved', value: 'APPROVED' },
		{ label: 'Rejected', value: 'REJECTED' }
		];
		this.selectedStatus = 'APPROVED';

	}

	init(){
		this.getPendingRequestsData('APPROVED');
	}

	// downloadData() {
	//   const blob = new Blob(this.pendingRequests, { type: 'application/octet-stream' });
	//   const url= window.URL.createObjectURL(blob);
	//   window.open(url);
	// }

	saveAsExcelFile(buffer: any, fileName: string) {
			let todaysDate : any;
			todaysDate = new Date();
			const data: Blob = new Blob([buffer], {
			type: this.EXCEL_TYPE,
			});
			// FileSaver.saveAs(
			// data,
			// fileName + '_export_' + todaysDate + this.EXCEL_EXTENSION
			// );

			// Create a link and trigger the download as shown before  
			const link = document.createElement('a');  
			link.href = URL.createObjectURL(data);  
			link.download = fileName + '_export_' + todaysDate +this.EXCEL_EXTENSION; 
			link.click();
		}

	exportAsExcelFile(json: any[], excelFileName: string) {
			const worksheet: XLSX.WorkSheet =XLSX.utils.json_to_sheet(json);
			const workbook: XLSX.WorkBook = {
			Sheets: { data: worksheet },
			SheetNames: ['data'],
			};
			const excelBuffer: any = XLSX.write(workbook, {
			bookType: 'xlsx',
			type: 'array',
			});
			this.saveAsExcelFile(excelBuffer, excelFileName);
		}


	statusChanged(status: any){
		console.log(status);
		this.pendingRequests = [];
		this.getPendingRequestsData(status);
	}

		getPendingRequestsData(status: any) {
			// let data: any = {};
			// data.user_id = this.user_id;
			// this.loading = true;

			this.userAccessApprovalService.getAllPendingRequests( this.GENERAL_CONFIG.base_url, this.globalService.userSettingsData.user, status).subscribe( (data: any) => {
				if (data.jsonObject.length > 0) {
					_.forEach(data.jsonObject, (item, index) => {
						item.INDEX = index;
					});
					this.pendingRequests = data.jsonObject;
					console.log("New user access data loaded");
				} else {
					console.log("Error");
				}
				}, (error) => {
				console.log(error);
				}, () => {
				console.log('Completed loading');
			});
		}

	//   cancel(){
	// 		this.diagRef.close();
	// 	};

}
