import { isNgTemplate } from '@angular/compiler';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import _ from 'lodash';
import * as Rx from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GridEventService implements OnInit, OnDestroy {
  listeners: any = {};
  eventsSubject: any;
  events: any;
  constructor() {
    this.listeners = {};
    this.eventsSubject = new Rx.Subject();

    this.events = Rx.from(this.eventsSubject);

    /* this.events.subscribe(
            ({name, args}:any) => {
                if (this.listeners[name]) {
                    for (let listener of this.listeners[name]) {
                        listener(...args);
                    }
                }
            }); */
  }
  ngOnInit(): void { }
  ngOnDestroy(): void {
    console.log('destoryed event service');
    this.listeners = {};
    this.events.next();
    if (this.eventsSubject) {
      this.eventsSubject.next();
      this.eventsSubject.complete();
    }
  }

  on(name: string | number, type: string, listener: any) {
    this.listeners[name] = this.listeners[name] || [];

    const gridListeners = this.listeners[name].filter(
      (item: any) => item.type == 'grid'
    );

    if (gridListeners.length > 0) {
      _.remove(this.listeners[name], (item: any) => item.type == 'grid');
    }

    this.listeners[name].push({
      type,
      fn: listener,
    });

    // console.log('event listner',this.listeners)

    return listener;
  }

  off(name: string | number, listener: any) {
    if (!this.listeners[name]) return;

    this.listeners[name] = this.listeners[name].filter(
      (x: any) => x.fn != listener
    );
  }

  broadcast(name: any, ...args: any) {
    this.eventsSubject.next({
      name,
      args,
    });
    if (
      this.events &&
      this.events.observers &&
      this.events.observers.length == 0
    ) {
      this.callSubscribe(name, args);
    }
  }
  callSubscribe(name: any, args: any) {
    this.events.subscribe(({ name, args }: any) => {
      if (this.listeners[name]) {
        for (let listener of this.listeners[name]) {
          listener.fn(...args);
        }
      }
    });
  }
  destroy() {
    this.listeners = {};
    this.events.next(true);
    this.eventsSubject.destroy();
  }
}
