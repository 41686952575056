import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GtwSnackbarService } from '../../../shared/_services/gtw-snackbar.service';
import { FormControl } from '@angular/forms';
import { environment } from 'projects/gtw-elements/src/environments/environment';
import { UserOnboardingService } from './user-onboarding.service';

@Component({
  selector: 'gtw-user-onboarding',
  templateUrl: './user-onboarding.component.html',
  styleUrls: ['./user-onboarding.component.scss']
})
export class UserOnboardingComponent implements OnInit {
	user!: any;
	loading!: boolean;
	client!: any;
	clientList!: any[];
	defaultClientData!: any[];
	crudLoading!: boolean;
	user_types!: { label: string; value: number }[];
	role_types!: string[];
	client_user_types!: { label: string; user_type_key: number }[];
	active!: any;
	user_type!: any;
	role_type: any;
	client_active: any;
	isLoadingEmail_to: boolean = false;
	filteredOptions: any;
	clientSelected = new FormControl();
	noRecords: any = false;

	@Output() emitEvent = new EventEmitter<string>();
	selectedClient: any;
	user_name: any;
	user_email_id!: string;
	GENERAL_CONFIG : any;
	pendingRequests: any;
	displayName: string = '';
	sendRequestBtn: any;
	globalService: any;
	appService: any = {
		USER_SETTINGS: '',
		SERVER_CONFIG: ''
	};

	constructor(
		private AlertService: GtwSnackbarService,
		private userOnboardingService: UserOnboardingService
	) {}

	ngOnInit(): void {
		this.GENERAL_CONFIG = environment;
		
		this.selectedClient = {
			CLIENT_NAME: '',
			CLIENT_KEY: ''
		};
		this.user = {
			user_type : ''
		};
		this.loading = false;
		this.client = {};
		this.clientList = [];
		
		this.defaultClientData = [];
		this.crudLoading = false;
		
		this.user_types = [
		{ label: 'User', value: 1 },
		{ label: 'Editor', value: 2 },
		{ label: 'Admin', value: 3 },
		{ label: 'Developer', value: 4 },
		{ label: 'Super Admin', value: 5 },
		];

		this.role_types = ['IT', 'External', 'AC', 'Functional'];

		this.client_user_types = [
		{ label: 'User', user_type_key: 1 },
		{ label: 'Editor', user_type_key: 2 },
		{ label: 'Admin', user_type_key: 3 },
		{ label: 'Developer', user_type_key: 4 },
		{ label: 'Super Admin', user_type_key: 5 },
		];

		this.active = '0';
		this.user_type = {};
		this.sendRequestBtn = false;
		
		let auth_key: any = sessionStorage.getItem('Authorization');
		let parseAuthKey = this.decodeToken(auth_key);
		this.user_name = parseAuthKey.uid;
		this.user_email_id = parseAuthKey.upn;
		this.getData();
	}

	decodeToken(token: string) {  
		const parts = token.split('.');  
		if (parts.length !== 3) {  
			throw new Error('Invalid JWT');  
		}  
		const payload = parts[1].replace(/-/g, '+').replace(/_/g, '/');  
		const decodedPayload = atob(payload);  
		return JSON.parse(decodedPayload);  
	};

	removeSelection(i: any){
		this.clientList.splice(i, 1);
	}

	getClient(val: any) {
		this.isLoadingEmail_to = true;
		if (val.length > 1) {
			this.isClientRoleSelected();

			this.userOnboardingService.getclientSearchData( '/admin', val ).subscribe( (data: any) => {
				if (data.data.length > 0) {
				console.log("New user access data loaded");
				this.filteredOptions=data.data;
				} else {
				console.log("Error");
				this.AlertService.openSnackBar(
					'No Records Found, Please search with a different keyword.','gtw-snackbar--red'
				);
				}
				}, (error) => {
				console.log(error);
				}, () => {
				console.log('Completed loading');
				});
			}else{
				if(val.length == 0){
					this.selectedClient = {};
				}
			this.isClientRoleSelected();
		}
	}

	addClient(client: any, email_type?: any) {
		this.selectedClient = client;
		this.isClientRoleSelected();
	}

	addSelection(){
		console.log(this.user);
		if(!this.selectedClient.CLIENT_NAME){
			this.AlertService.openSnackBar('Client field cannot be empty', 'gtw-snackbar--red');
			return;
		};
		this.clientList.push({
			client_name: this.selectedClient.CLIENT_NAME,
			client_key: this.selectedClient.CLIENT_KEY,
			user_type_key: this.user.user_type
		});
		this.clientSelected.reset('');
		this.user = {};
	}

	isClientRoleSelected() {
		if(!!this.selectedClient.CLIENT_NAME && !!this.user.user_type){
			this.sendRequestBtn = true;
		}else{
			this.sendRequestBtn = false;
		}
	}

	getData() {
		this.loading = true;
		this.pendingRequests = [];

		this.userOnboardingService.getNewUserData( '/admin', this.user_email_id).subscribe( (data: any) => {
			if (data.access_requests.length > 0) {
				this.pendingRequests = data.access_requests;
				this.displayName = data.user.display_name;
				console.log("New user access data loaded");
				} else {
				console.log("Error");
				}
			}, (error) => {
				console.log(error);
			}, () => {
				console.log('Completed loading');
		});
	}

	requestApproval(){
		console.log(this.user);
		if(!this.selectedClient.CLIENT_NAME){
			this.AlertService.openSnackBar('Client field cannot be empty', 'gtw-snackbar--red');
			return;
		};
	
		let userData : any =[]; 

		userData.push({
			client_key: this.selectedClient.CLIENT_KEY,
			client_name: this.selectedClient.CLIENT_NAME,
			user_email: this.user_email_id,
			user_type: this.user.user_type,
			display_name: this.displayName,
		})

		this.userOnboardingService.requestUserAccess( '/admin', this.user_email_id, userData).subscribe( (data: any) => {
			console.log("Request User Access Response ", data);
			if (data.callSuccess === '1') {
				if(data.error == null){
					this.AlertService.openSnackBar('Request has been sent for approval.', 'gtw-snackbar--green');
				}else{
					this.AlertService.openSnackBar(data.error, 'gtw-snackbar--yellow');
				}
				this.filteredOptions = [];
				console.log(data);
				console.log("New user access data loaded");
				
				this.clientSelected.reset('');
				this.user = {};
				this.getData();
			} else {
				console.log("Error");
				this.AlertService.openSnackBar('Error sending the request for approval.', 'gtw-snackbar--red');
			}
			}, (error) => {
			console.log(error);
			}, () => {
			console.log('Completed loading');
		});
	}

	sendReminderMail(access_data: any){
		let userData = {
			client_key: access_data.client_name,
			user_email: this.user_email_id,
			user_type: access_data.user_type_key,
			request_key: access_data.REQUEST_KEY
		}
		this.userOnboardingService.sendReminderMail( '/admin', this.user_email_id, userData).subscribe( (data: any) => {
			console.log("Send reminder response in component", data);
			if (data.callSuccess === '1') {
				console.log("New user access data loaded");
				this.AlertService.openSnackBar('Request has been sent for approval.', 'gtw-snackbar--green');
			} else {
				console.log("Error");
				this.AlertService.openSnackBar('Error sending the request for approval.', 'gtw-snackbar--red');
			}
			}, (error) => {
			console.log(error);
			}, () => {
			console.log('Completed loading');
		});
	}

	cancel() {
		this.emitEvent.emit('cancel');
	}
}
