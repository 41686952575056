import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersComponentComponent } from './users-component.component';
import { UserBusinessComponent } from './user-business/user-business.component';
import { AccordionsModule } from 'appkit-angular/accordions';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedDirectivesModule } from '../shared/_directives/shared-directives.module';
import { LoadingModule } from 'appkit-angular/loading';
import { FilterObjPipe } from '../shared/pipes/filter-obj.pipe';


@NgModule({
  declarations: [UsersComponentComponent, UserBusinessComponent, FilterObjPipe],
  imports: [
    CommonModule,
    AccordionsModule,
    MatDialogModule,
    SharedDirectivesModule,
    LoadingModule
  ],
  providers: [  ],
  exports: [UsersComponentComponent, UserBusinessComponent]
})
export class UsersComponentModule { }
