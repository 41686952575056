import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
//import { StateService } from '@uirouter/core';
//import { AppService } from '../../../app.service';
//import { GridEventService } from '../../../core/grid/services/grid-event.service';
import { GtwSnackbarService } from '../../../shared/_services/gtw-snackbar.service';
//import { setFormData } from '../../shared/admin.utilities';
//import { UserAccessRequestService } from '../user-access-request/user-access-request.service';
//import { UserAdminService } from '../users-component/user-admin.service';
import { UserAccessApprovalService } from './user-access-approval.service';
import { environment } from 'projects/gtw-elements/src/environments/environment';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import _ from 'lodash';
//import { GlobalService } from '../../../global/services/global.service';

import { TemplateAssignRequestApprovalComponent } from './template-assign-request-approval/template-assign-request-approval.component';
import { BusinessAssignRequestApprovalComponent } from './business-assign-request-approval/business-assign-request-approval.component';
import { RejectUserRequestComponent } from './reject-user-request/reject-user-request.component';
import { ViewRequestHistoryComponent } from './view-request-history/view-request-history.component';
import { GtwElementsBaseUrlsModel } from '../../../shared/_models/gtw-elements-base-urls.model';

@Component({
  selector: 'gtw-user-access-approval',
  templateUrl: './user-access-approval.component.html',
  styleUrls: ['./user-access-approval.component.scss']
})
export class UserAccessApprovalComponent implements OnInit {

  	user_id: any;
	user!: any;
	loading!: boolean;
	client!: any;
	clientList!: any[];
	settings: any;
	defaultClientData!: any[];
	crudLoading!: boolean;
	user_types!: { label: string; value: number }[];
	role_types!: string[];
	client_user_types!: { label: string; user_type_key: number }[];
	active!: any;
	user_type!: any;
	role_type: any;
	client_active: any;
	isLoadingEmail_to: boolean = false;
	filteredOptions: any;
	clientSelected = new FormControl();

	@Input() rowData: any;
	@Output() emitEvent = new EventEmitter<string>();
	selectedClient: any;
	user_name: any;
	GENERAL_CONFIG : any;
	pendingRequests: any;
	globalService: any;
	appService: any = {
		USER_SETTINGS: '',
		SERVER_CONFIG: ''
	};
	baseURLs: any;

	@Input('base-urls')
	set _base_urls(baseUrls: string){
		try{
			let urls:GtwElementsBaseUrlsModel = JSON.parse(baseUrls);
			if(Object.keys(urls).length > 0){
				this.baseURLs = urls;
			}
		}catch(e){
			console.log("Input Not yet stable. Still waiting to get resolved.");
		}
	}

	@Input('header-data')
	set headerData(headerData : any){
		try{
			this.globalService = JSON.parse(headerData);

			this.appService.USER_SETTINGS = this.globalService.userSettingsData;
			this.appService.SERVER_CONFIG = this.globalService.serverConfigData;
			this.GENERAL_CONFIG = this.globalService.generalConfigData;
			this.init();

		}catch(e){			
			console.log("Input Not yet stable. Still waiting to get resolved.");
		}
	}

	constructor(
		//private stateService: StateService,
		//private appService: AppService,
		//private UserAdminFactory: UserAdminService,
		private AlertService: GtwSnackbarService,
		//private gridEventService: GridEventService,
		private userAccessApprovalService: UserAccessApprovalService, 
		//private globalService: GlobalService,
    private dialog: MatDialog
	) {}

	ngOnInit(): void {
		//this.GENERAL_CONFIG = environment;
		console.log(this.rowData);
		
		this.user = {};
		this.loading = false;
		this.client = {};
		this.clientList = [];
		
		this.defaultClientData = [];
		this.crudLoading = false;
		
		this.user_types = [
		{ label: 'User', value: 1 },
		{ label: 'Editor', value: 2 },
		{ label: 'Admin', value: 3 },
		{ label: 'Developer', value: 4 },
		{ label: 'Super Admin', value: 5 },
		];

		this.role_types = ['IT', 'External', 'AC', 'Functional'];

		this.client_user_types = [
		{ label: 'User', user_type_key: 1 },
		{ label: 'Editor', user_type_key: 2 },
		{ label: 'Admin', user_type_key: 3 },
		{ label: 'Developer', user_type_key: 4 },
		{ label: 'Super Admin', user_type_key: 5 },
		];

		this.active = '0';
		this.user_type = {};
		
	}

	init(){
		this.user_id = this.appService.USER_SETTINGS.user.sso_id;
		this.user_name = this.appService.USER_SETTINGS.user.display_name;
		this.settings = this.appService.SERVER_CONFIG.configSettings;
		this.getPendingRequestsData();
	}

	// viewHistory(){
	// 	console.log("View History");

	// 	const dialogConfig = new MatDialogConfig();
	// 	dialogConfig.panelClass = 'modal-lg';
	// 	dialogConfig.position = {top:'15px'}
	// 	dialogConfig.data = this.globalService;
	// 	const modalRef = this.dialog.open(ViewRequestHistoryComponent, dialogConfig);
	// }

	getPendingRequestsData() {
		let data: any = {};
		data.user_id = this.user_id;
		this.loading = true;
		this.pendingRequests = [];

		this.userAccessApprovalService.getAllPendingRequests( this.GENERAL_CONFIG.base_url, this.appService.USER_SETTINGS.user, 'CREATED').subscribe( (data: any) => {
			if (data.jsonObject.length > 0) {
				_.forEach(data.jsonObject, (item, index) => {
					item.INDEX = index;
				});
				this.pendingRequests = data.jsonObject;
				console.log("New user access data loaded");
			  } else {
				console.log("Error");
			  }
			}, (error) => {
			  console.log(error);
			}, () => {
			  console.log('Completed loading');
		});
	}

	approveRequest(index: any, access_data: any){
		console.log(index, access_data);
		let businessData = this.userAccessApprovalService.getBusinessAssignData(index);
		let meKeys: any = [];
		if(!businessData && !access_data.templatesData){
			this.AlertService.openSnackBar('Please assign Business and Template before approving the request', 'gtw-snackbar--red');
			return;
		}

		if(!businessData){
			this.AlertService.openSnackBar('Please assign Business before approving the request', 'gtw-snackbar--red');
			return;
		}else{
			businessData.forEach((business: any) => {
				meKeys.push(business.ME_KEY);
			});
		} 

		if(!access_data.templatesData){
			this.AlertService.openSnackBar('Please assign Template before approving the request', 'gtw-snackbar--red');
			return;
		}

		let userData : any = {
			email_id: access_data.USER_EMAIL,
			template_name: Object.keys(access_data.templatesData).toString(),
			template_key: Object.values(access_data.templatesData).toString(),
			grp_obj_key: businessData[0].GRP_OBJ_KEY,
			me_key: meKeys.toString(),
			system_user_type: access_data.REQUESTED_USER_TYPE,
			c_user_type: access_data.REQUESTED_USER_TYPE,
			request_key: access_data.REQUEST_KEY,
            client_key: access_data.CLIENT_KEY
		} 

		this.userAccessApprovalService.approveUserAccess(
			this.GENERAL_CONFIG.admin_api_url, 
			this.appService.USER_SETTINGS.user, 
			this.globalService,
			userData, businessData).subscribe( (data: any) => {
				if (data.client.p_retval === "1" && data.core.p_retval === "1") {
					console.log("New user access data loaded");
					this.AlertService.openSnackBar('Request has been approved successfully', 'gtw-snackbar--green');
					this.getPendingRequestsData();
				}else if (data.client.p_retval === "0"){
					this.AlertService.openSnackBar(JSON.parse(data.client).p_error_out, 'gtw-snackbar--red');
				}else if (data.core.p_retval === "0"){
					this.AlertService.openSnackBar(JSON.parse(data.client).p_error_out, 'gtw-snackbar--red');
				} else {
					console.log("Error");
					this.AlertService.openSnackBar('Error while approving the request', 'gtw-snackbar--red');
				}
			}, (error) => {
				console.log(error);
			}, () => {
			console.log('Completed loading');
	  	});
	}

	rejectRequest(index: any, access_data: any){
		console.log(index, access_data);
		access_data.globalService = this.globalService;
		const dialogConfig = new MatDialogConfig();
		dialogConfig.panelClass = 'modal-sm';
		dialogConfig.position = {top:'15px'};
		dialogConfig.data = access_data;
		const modalRef = this.dialog.open(RejectUserRequestComponent, dialogConfig);

		modalRef.afterClosed().subscribe(result => {
			console.log("Request is rejected");
			this.getPendingRequestsData();
		});
	}

	sendReminderMail(access_data: any){
		let userData = {
			client_key: access_data.client_name,
            user_email: this.appService.USER_SETTINGS.user.email,
            user_type: access_data.user_type_key,
			request_key: access_data.REQUEST_KEY
		}
		this.userAccessApprovalService.sendReminderMail( this.GENERAL_CONFIG.base_url, this.appService.USER_SETTINGS.user, userData).subscribe( (data: any) => {
			if (data.length > 0) {
			  console.log("New user access data loaded");
			  this.AlertService.openSnackBar('Reminder mail has been sent.', 'gtw-snackbar--green');
			} else {
			  console.log("Error");
			  this.AlertService.openSnackBar('Error while sending the reminder mail.', 'gtw-snackbar--red');
			}
		  }, (error) => {
			console.log(error);
		  }, () => {
			console.log('Completed loading');
	  });
	}
  
	assignBusiness(i: any){
		let data = {
			index: i,
			globalService: this.globalService,
			baseURLs: this.baseURLs
		}
		const dialogConfig = new MatDialogConfig();
		dialogConfig.panelClass = 'modal-lg';
		dialogConfig.position = {top:'15px'}
		dialogConfig.data = data;

		const modalRef = this.dialog.open(BusinessAssignRequestApprovalComponent, dialogConfig);
	}

  	assignTemplates(i: any, item: any){
		item.globalService = this.globalService;
		item.baseURLs = this.baseURLs;
		// item.templatesData = {
		// 	"1": 1002,
		// 	"11": 884,
		// 	"12": 888,
		// 	"13": 893,
		// 	"14": 894,
		// 	"16": 896,
		// 	"17": 886,
		// 	"18": 926
		// };
		let rowLevelData = this.userAccessApprovalService.getTemplateAssignData(i);
		if(rowLevelData){
			item.templatesData = rowLevelData.templatesData;
		}
		
		const dialogConfig = new MatDialogConfig();
		dialogConfig.panelClass = 'modal-lg';
		dialogConfig.position = {top:'15px'}

		dialogConfig.data = item;

		const modalRef = this.dialog.open(TemplateAssignRequestApprovalComponent, dialogConfig);
  	}

	cancel() {
		this.emitEvent.emit('cancel');
	}

}
