import { Component, ElementRef, Input, OnInit } from '@angular/core';
import _ from 'lodash';

@Component({
    selector: 'app-gt-business-assign-tree',
    template: `
    <ul>
      <li *ngFor="let node of menuData" [hidden]="node.hidden">
        <div (click)="menuClick(node, $event)" [ngClass]="node.children ? 'level' : 'node'" class="depth_{{ node.level }}">
          <div class="pull-left">
            <input (click)="updateSelected(node, $event)" type="checkbox" [(ngModel)]="node.selected" [disabled]="node.disableCheckbox" />
            <span *ngIf="node.numSelected > 0" style="background-color:#f5857c" class="badge">{{ node.numSelected }}</span>
            {{ node.name }} - {{ node.key }}
          </div>
          <div class="pull-right">
            <i *ngIf="node.children && node.collapsed" class="fa fa-folder"></i>
            <i *ngIf="node.children && !node.collapsed" class="fa fa-folder-open"></i>
            <i *ngIf="!node.children && node.selected" style="color:#1ca5cf" class="fa fa-circle"></i>
          </div>
        </div>
        <app-gt-business-assign-tree [hidden]="node.collapsed"  [menuData]="node.children"></app-gt-business-assign-tree>
      </li>
    </ul>
  `,
    //   styleUrls: ['./gt-business-assign-tree.component.css'], // Add your CSS file path
})
export class GtBusinessAssignTreeDirective implements OnInit {
    @Input()
    menuData!: any[];
    treeId: any;
    nodeLabel: any;
    nodeChildren: any;

    constructor(private hostElement: ElementRef) { }

    ngOnInit(): void {
        this.treeId = this.hostElement.nativeElement.getAttribute('treeId');
        this.nodeLabel = this.hostElement.nativeElement.getAttribute('nodeLabel') || 'name';
        this.nodeChildren = this.hostElement.nativeElement.getAttribute('nodeChildren') || 'children';
    }

    menuClick(node: any, event: any) {
        if (node.type === 'activity') {
            this.toggleFolder(node);
        } else {
            this.toggleFolder(node);
        }
        event.stopPropagation();
    }

    updateSelected(node: any, event: any) {
        event.stopPropagation();
        if (node.children) {
            this.selectBranch(node, (node.selected) ? true : false);
        }
        this.parentAllCheck(node);
        this.countActiveNodes();
    }

    toggleFolder(node: { collapsed: boolean; }) {
        node.collapsed = !node.collapsed;
    };

    selectBranch(node: { children: any; },value: boolean){
        this.setTreeAttribute(node.children,'selected','children',value);
    };

    setTreeAttribute(_collection: string | any[],_predicate: string,_children: string,value: boolean){
        for (let i = 0; i < _collection.length; i++) {
            let  n = _collection[i][_predicate];
            let children = _collection[i][_children];
            if(children && children.length ){
                this.setTreeAttribute(children,_predicate,_children,value )
            }
            _collection[i][_predicate] = value;
        }
    }

    parentAllCheck(node: { parent: any }){
        let allChecked = true;
        let parent = node.parent;
        if(parent && parent.children){
            _.forEach(node.parent.children, function(value, key) {
                if(!value.selected) {
                    allChecked = false;
                }

            });
            parent.selected = (allChecked) ? true : false;
            //IF PARENT HAS A PARENTS CHECK NODE ALSO
            if(parent.parent ){
                this.parentAllCheck(parent);
            }
        }
    };

    countActiveNodes(){
        _.forEach(this.menuData, (value, key) => {
            value.numSelected = this.CountNodes(value)
        })
    }

    CountNodes(node: any){
        var selectedNum = 0;
         function countChildren(node: { children: any; numSelected: number; }){
             var nodeCount = 0;
             _.forEach(node.children, function(value, key) {
                 if(value.children){
                     nodeCount += countChildren(value);
                 }else if( value.selected ){
                     ++selectedNum
                     ++nodeCount;
                 }
             });

             node.numSelected = nodeCount;
             return nodeCount;
         }
        countChildren(node);
        return selectedNum;
     }
}
