<div class="modal-content">
    <div class="div-message-box animated fadeIn fast">
      <div class="message-box-container animated fadeIn fast">
        <div class="message-box-middle">
          <span class="msg-title">
            <i class="fa fa-sign-out txt-color-orangeDark"></i>Confirm 
          </span>
          <p class="p-text" *ngIf="pushData">Are you sure you want to push pdf's to Efile?</p>
          <p class="p-text" *ngIf="!pushData">Are you sure you want to Delete pdf's from Efile?</p>
          <div class="message-box-button-section" >
            <button class="btn btn-secondary btn-xs pushBtn" id="switch-no" (click)="cancel()">No</button>
            <button class="btn btn-danger btn-xs pushBtn" *ngIf="pushData" id="switch-yes" (click)="pushPdfToEfile()">
              <span><i class="fa fa-spin fa-spinner" *ngIf="loading"></i></span> Yes </button>
              <button class="btn btn-danger btn-xs pushBtn" *ngIf="!pushData" id="switch-yes" (click)="deleteForm()">
                <span><i class="fa fa-spin fa-spinner" *ngIf="loading"></i></span> Yes </button>
          </div>
        </div>
      </div>
    </div>
  </div>