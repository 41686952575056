import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Observable } from 'rxjs';
import { ActionService } from '../../shared/_services/action.service';
import { HttpParams } from '@angular/common/http';

interface InputParams{
  submission_id: string;
  clientKey: number;
  tax_year: number;
  scenario: number;
}

@Component({
  selector: 'review-diagnostics',
  templateUrl: './review-diagnostics.component.html',
  styleUrls: ['./review-diagnostics.component.scss'],
})
export class ReviewDiagnostics {

  lastReviewedDate: string;

  @Input()
  gtwApiUrl: string;

  private _params: InputParams | undefined;

  @Input()
  set params(value: string) {
    try {

      this._params = JSON.parse(value);
      this.loadData();
    } catch (error) {
      console.error('Invalid JSON string for params:', value);
      this._params = undefined;
    }
  }

  @Output('on-data-loaded')
  onDataLoaded: EventEmitter<boolean>;

  @Output('on-cancel')
  onCancel: EventEmitter<void>;

  @Output('on-ignore')
  onIgnore: EventEmitter<void>;

  columns = [
    {
      title: 'Diagnostic Name',
      data: 'DIAG_NAME',
      searchable: false,
      formatAmount: false,
      visible: true,
      
    },
    {
      title:'Failed Count',
      data: 'DIAG_FAILED_CNT',
      searchable: false,
      formatAmount: false,
      visible: true,
      width: '145px'
      
    },
  ];

  data = [];

  isLoading: boolean = false;
  ignore: boolean = false;
  hasDiagnostics: boolean = false;
  showBulkMessage: boolean = false;

  constructor(private actionService: ActionService ) {
    this.lastReviewedDate = '12/2/2024 23:34';
    this.gtwApiUrl = '/gtw';
    this.onDataLoaded = new EventEmitter();
    this.onCancel = new EventEmitter();
    this.onIgnore = new EventEmitter();
  }


  loadData(){
    if(!this._params) return;

    const params = new HttpParams({
      fromObject: {
        submission_id: this._params.submission_id.toString(),
        tax_year: this._params.tax_year.toString(),
        scenario: this._params.scenario.toString(),
        jcd_key: '250'
      }
    });

    this.isLoading = true
    this.actionService.getActionData(this.gtwApiUrl, 31996, this._params?.clientKey, params).subscribe((data) => {
      this.isLoading = false
      this.data = data.jsonObject.filter((d: any) => d.DIAG_FAILED_CNT > 0);
      this.hasDiagnostics = this.data.length > 0;

      this.showBulkMessage = this._params!.submission_id.includes(',') && this.hasDiagnostics;

      this.onDataLoaded.emit(this.hasDiagnostics); 
    });

  }

  cancel() {
   this.onCancel.emit();
  }

  doIgnore(){
    this.onIgnore.emit();
    this.ignore = true;
  }

}
