import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialog, MefEfileChangesComponent } from './components/mef-efile-changes/mef-efile-changes.component';
import {AppNgAppkitModule} from '../app-ng-appkit.module';
import { AppNgMaterialModule } from '../app-ng-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminService } from './admin.service';
import { NaicsMaintenanceComponent } from './components/naics-maintenance/naics-maintenance.component';
import { EntityChartMapCleanUpComponent } from './components/entity-chart-map-clean-up/entity-chart-map-clean-up.component';
import { S3FileUpload } from './components/s3-file-upload/s3-file-upload.component';
import { SharedComponentsModule } from '../shared/components/shared-components.module';
import { S3FileUploadService } from './components/s3-file-upload/s3-file-upload.service';
import { DfMappingChangesComponent } from './components/df-mapping-changes/df-mapping-changes.component';
import { S3FolderManagerComponent } from './components/s3-folder-manager/s3-folder-manager.component';
import { PostingPartnersComponent } from './components/posting-partners/posting-partners.component';
import { UserAccessRequestComponent } from './components/user-access-request/user-access-request.component';
import { UserAccessApprovalComponent } from './components/user-access-approval/user-access-approval.component';
import { TemplateAssignRequestApprovalComponent } from './components/user-access-approval/template-assign-request-approval/template-assign-request-approval.component';
import { BusinessAssignRequestApprovalComponent } from './components/user-access-approval/business-assign-request-approval/business-assign-request-approval.component';
import { RejectUserRequestComponent } from './components/user-access-approval/reject-user-request/reject-user-request.component';
import { ViewRequestHistoryComponent } from './components/user-access-approval/view-request-history/view-request-history.component';
import { UsersComponentModule } from '../users-component/users-component.module';
import { UserOnboardingComponent } from './components/user-onboarding/user-onboarding.component';


@NgModule({
  declarations: [MefEfileChangesComponent, ConfirmDialog, NaicsMaintenanceComponent, EntityChartMapCleanUpComponent,S3FileUpload, DfMappingChangesComponent,S3FolderManagerComponent, PostingPartnersComponent, UserAccessRequestComponent, UserAccessApprovalComponent, TemplateAssignRequestApprovalComponent, BusinessAssignRequestApprovalComponent, RejectUserRequestComponent, ViewRequestHistoryComponent, UserOnboardingComponent],
  imports: [
    CommonModule,
    AppNgAppkitModule,
    AppNgMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedComponentsModule,
    UsersComponentModule
  ],
  exports: [MefEfileChangesComponent, ConfirmDialog, NaicsMaintenanceComponent,S3FileUpload,DfMappingChangesComponent,S3FolderManagerComponent],
  providers: [AdminService,S3FileUploadService],
})
export class AdminModule { }
