<!-- 	
	<div class="modal-header">
		<h3 class="modal-title"> {{title}} <i (click)="cancel()" class="fa fa-times pull-right"></i>
		<i class="fa pull-right" [ngClass]="{'fa-plus-square-o': minimise, 'fa-minus-square-o': !minimise}" style= "margin-right: 10px" (click)="minimise=!minimise"></i>
		</h3>
	</div>
	<hr>

	<div class="panel-body"> 
		<div  class="container-border" style="padding: 10px;">
			<div class="row" style="">
            
                <div class="col-md-12">
                    <div class="panel panel-default">
                        <div class="panel-body" style="padding: 2px;height: 500px;overflow-y: auto;overflow-x: hidden;">
                            <div style="margin-bottom: 10px;">
                                Status : 
                                <select required class="input-sm" name="user_type" (change)="statusChanged(selectedStatus)"
                                    [(ngModel)]="selectedStatus" style=" padding: 0px; border: 1px solid #ccc">
                                    <option *ngFor="let s of requestStatus" [value]="s.value">{{ s.label }}</option>
                                </select>
                            </div>
                            <table class="gt-client table table-bordered table-striped table-condensed"
                                style="margin-bottom: 0px">
                                <thead>
                                    <tr>
                                        <th style="">Requested By</th>
                                        <th style="">Client</th>
                                        <th style="">Role</th>
                                        <th>Status</th>
                                        <th>Approver Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of pendingRequests; let i = index"
                                    [ngClass]="{'active-client': item.active && item.user_type_key !== null}">
                                    <td>
                                        {{item.USER_EMAIL}}
                                    </td>
                                    <td>
                                        {{ item.CLIENT_NAME }}
                                    </td>
                                    <td>
                                        {{item.REQUESTED_USER_TYPE}}
                                    </td>
                                    <td>
                                        {{item.REQUEST_STATUS}}
                                    </td>
                                    <td>
                                        {{item.APPROVER_NAME}}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
		</div> 
	</div>

	<div class="modal-footer" *ngIf="!loading" uib-collapse="minimise">
		<button class="btn btn-warning btn-sm" type="button"  (click)="cancel()">Cancel</button>
	</div> -->

    <div class="row" style="">
            
        <div class="col-md-12">
            <div class="panel panel-default">
                <div class="panel-body" style="padding: 2px;height: 500px;overflow-y: auto;overflow-x: hidden;">
                    <div style="margin-bottom: 10px;">
                        Status : 
                        <select required class="input-sm" name="user_type" (change)="statusChanged(selectedStatus)"
                            [(ngModel)]="selectedStatus" style=" padding: 0px; border: 1px solid #ccc">
                            <option *ngFor="let s of requestStatus" [value]="s.value">{{ s.label }}</option>
                        </select>
                        <span class="fa fa-refresh refreshIcon" (click)="statusChanged(selectedStatus)"></span>
                        <span style="float: right;">
                            <button class="btn btn-success btn-sm" type="button" (click)="exportAsExcelFile(pendingRequests, 'Request_History')"><span class="fa fa-file-excel-o"></span> Export To Excel</button>
                        </span>
                    </div>
                    <table class="gt-client table table-bordered table-striped table-condensed"
                        style="margin-bottom: 0px">
                        <thead>
                            <tr>
                                <th style="">Requested By</th>
                                <th style="">Client</th>
                                <th style="">Role</th>
                                <th>Status</th>
                                <th>Approver</th>
                                <th>Requested Date</th>
                                <th>Approved Date</th>
                                <th>Requested By Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of pendingRequests; let i = index"
                            [ngClass]="{'active-client': item.active && item.user_type_key !== null}">
                            <td>
                                {{item.DISPLAY_NAME}}
                            </td>
                            <td>
                                {{ item.CLIENT_NAME }}
                            </td>
                            <td>
                                {{item.REQUESTED_USER_TYPE}}
                            </td>
                            <td>
                                {{item.REQUEST_STATUS}}
                            </td>
                            <td>
                                {{item.APPROVER_NAME}}
                            </td>
                            <td>
                                {{item.CREATED_ON}}
                            </td>
                            <td>
                                {{item.UPDATED_ON}}
                            </td>
                            <td>
                                {{item.USER_EMAIL}}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>