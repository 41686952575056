import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  	providedIn: 'root'
})
export class UserAccessRequestService {

	url ={
		getUserData : '/user/access/checkUser',
		getSearchClient : '/user/access/clients?client_key=0&client_name=',
		requestAccess :'/user/access/requestAccess',
    	sendReminder:'/user/access/request/notify'
	}
  	durationInSeconds= 5;

  	constructor(private httpClient: HttpClient, 
		private http:HttpClient, 
		private _snackBar: MatSnackBar) { }


	getNewUserData(baseURL:string, user_data: any){
		//let clientKey = sessionStorage.getItem('client_key') || '';
		const options= {withCredentials:true}
		const url = `${baseURL}${this.url.getUserData}`;

		let body = new FormData();
		body.append('sso_id', user_data.sso_id.toString());
		body.append('email', user_data.email.toString());
		
		return this.http.post(url, body, options).pipe(
			map((res:any) => { 
				if(res.callSuccess == "1"){
				return res;
				} 
				//this.openSnackBar('Unable to fetch data.','gtw-snackbar--red');
				throwError(new Error('oops!'));
				return null;
			}
		));
	}

	getclientSearchData(baseURL:string, keyword: any){
		//let clientKey = sessionStorage.getItem('client_key') || '';
		const options= {withCredentials:true}
		const url = `${baseURL}${this.url.getSearchClient + keyword}`;
		
		return this.http.get(url);
	}

	requestUserAccess(baseURL:string, user_data: any, request_data: any){
		//let clientKey = sessionStorage.getItem('client_key') || '';
		const options= {withCredentials:true}
		const url = `${baseURL}${this.url.requestAccess}`;

		let body = new FormData();
		
		body.append('requester_email', user_data.email.toString());
		body.append('clob_data', JSON.stringify(request_data));
		
		return this.http.post(url, body, options).pipe(
			map((res:any) => { 
				if(res.callSuccess == "1"){
				return res;
				} 
				//this.openSnackBar('Unable to fetch data.','gtw-snackbar--red');
				throwError(new Error('oops!'));
				return null;
			}
		));
	}

	sendReminderMail(baseURL:string, user_data: any, request_data: any){
		//let clientKey = sessionStorage.getItem('client_key') || '';
		const options= {withCredentials:true}
		const url = `${baseURL}${this.url.sendReminder}`;

		let body = new FormData();
		
		body.append('requester_email', user_data.email.toString());
		body.append('request_key', request_data.request_key.toString());
		
		return this.http.post(url, body, options).pipe(
			map((res:any) => { 
				if(res.callSuccess == "1"){
				return res;
				console.log("Send Reminder Response", res.jsonObject);
				} 
				//this.openSnackBar('Unable to fetch data.','gtw-snackbar--red');
				throwError(new Error('oops!'));
				return null;
			}
		));
	}
}
