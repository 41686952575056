import { NgModule } from '@angular/core';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { EfileRoutingModule } from './efile-routing.module';
import { SubmissionDataComponent } from './submission-data/submission-data.component';
import { SubmissionDetailComponent } from './submission-data/submission-detail/submission-detail.component';
import { UploadIRSComponent } from './upload-irs-schema/upload-irs.component';

import { AppNgAppkitModule } from '../app-ng-appkit.module';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { CellHighlightDirective } from './directives/cell-highlighter';
import { SubmitComponent } from './submission-data/submit/submit.component';
import { DashboardComponent } from './submission-data/dashboard/dashboard.component';
import { ReviewDiagnostics } from './submit-confirmation/review-diagnostics.component';
import { AdminModule } from '../admin/admin.module';
import { EfileReviewAttachmentUpload } from './review-attachment-upload/review-attachment-upload.component';
import { TableModule } from 'primeng-lts/table';


const components = [
  SubmissionDataComponent,
  UploadIRSComponent,
  SubmissionDetailComponent,
  ReviewDiagnostics,
  EfileReviewAttachmentUpload
]

@NgModule({
  declarations: [
    ...components,
    CellHighlightDirective,
    SubmitComponent,
    DashboardComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    EfileRoutingModule,
    AppNgAppkitModule,
    CdkScrollableModule,
    MatTabsModule,
    MatIconModule,
    AdminModule,
    TableModule
  ],
  exports: [...components],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' }]

})
export class EfileModule { }
