<!-- Start Header -->
<div class="modal-header">
    <h3 class="modal-title"> {{ title }}
        <i (click)="cancel()" class="fa fa-times pull-right"></i>
    </h3>
</div>
<!-- End Header -->

<!-- Start Body -->
<div class="modal-body">
    
    <!-- <ap-tabs-group>
        <ap-tab [label]="'Details'">
            <gtw-user-details [rowData]="rowData" (emitEvent)="eventType($event)" ></gtw-user-details>
        </ap-tab>
        <ap-tab [label]="'Module Assign'">
            <gtw-user-modules [rowData]="rowData" (emitEvent)="eventType($event)" ></gtw-user-modules>
        </ap-tab>
        <ap-tab [label]="'Business Assign'">
            <gtw-user-business [rowData]="rowData" (emitEvent)="eventType($event)"></gtw-user-business>
        </ap-tab>
        <ap-tab [label]="'Audit'">
            <gtw-user-audit [rowData]="rowData" (emitEvent)="eventType($event)"></gtw-user-audit>
        </ap-tab>
    </ap-tabs-group> -->
</div>