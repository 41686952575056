<div class="container-fluid">
  <div class="row flex-row justify-content-center" *ngIf="isLoading">
    <ap-loading [size]="'24px'" [colorName]="'a-primary'" loadingText="Checking for open diagnostics..."></ap-loading>

  </div>
  <div class="row table-container" *ngIf="!isLoading">
    <p-table #dt *ngIf="!ignore && !showBulkMessage" [columns]="columns" 
      [value]="data" [loading]="isLoading" [virtualRowHeight]="40">
      <ng-template pTemplate="header" let-columns>
        <!-- <tr [style.height]="'35px'"> -->
        <tr style="height: 30px;padding: 5px;">

          <ng-container *ngFor="let col of columns; index as i">
            <th style="width:30px" *ngIf="col.data === null"></th>
            <th *ngIf="col.data != null && col.visible" [pSortableColumn]="col.data" [ngStyle]="{width: col.width || 'inherit'}">
              <span>{{ col.title }}
                <p-sortIcon [field]="col.data"></p-sortIcon>
              </span>
            </th>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-i="rowIndex">
        <tr style="height: 30px;padding: 5px;">
          <td *ngFor="let col of columns" [ngClass]="{'drill-down': col.data == null}">
            <span *ngIf="(col.data != null && rowData[col.data] === 'Critical') || 
                                        (col.data != null && rowData[col.data] === 'Informational')" class="appkiticon"
              [ngClass]="{'icon-alert-fill': rowData[col.data] === 'Critical',
                                           'icon-information-fill': rowData[col.data] === 'Informational'}" [ngStyle]="{'color': rowData[col.data] === 'Critical' ? '#D04A02' : '#212121',
                                         'margin-right':'10px'}">
            </span>
            <span *ngIf="col.data == 'FAILCNT'"
              [ngStyle]="{'color':(rowData[col.data] > 0 && rowData.DIAG_MSG == 'Critical') ? '#D04A02' : '#212121'}" >
              {{rowData[col.data]}}</span>
            <span *ngIf="col.data != null && col.data != 'FAILCNT'"
              [ngStyle]="{'color':rowData[col.data] > 0 ? 'red' : 'black'}">
              {{rowData[col.data]}}</span>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <strong class="w-100 text-center" *ngIf="ignore">Please make sure all the diagnostics are cleared before this submission is filed to IRS.</strong>
  </div>

  <div class="row flex-row justify-content-end a-mt-10">
    <ap-button class="a-mr-10" [btnText]="'Ignore Diagnostics'" [config]="{ isPrimary: true }" (onBtnClick)="doIgnore()" *ngIf="hasDiagnostics && !ignore">
    </ap-button>
    <ap-button [btnText]="'Ok'" [config]="{ isPrimary: true }" (onBtnClick)="cancel()" *ngIf="!hasDiagnostics || ignore">
    </ap-button>
  </div>
</div>