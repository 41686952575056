import {NgModule} from '@angular/core';
import {DSTClickOutsideDirective} from './dstClickOutside.directive';
import {DragAndDropUploadDirective} from './drag-and-drop-upload.directive';
import { GtBusSearchDirective } from './GtBusSearchDirective';
import { GtBusinessAssignTreeDirective } from './GtBusinessAssignTreeDirective';
import { GtBusinessAssignMenuComponent } from './GtBusinessAssignMenuDirective';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LoadingModule } from 'appkit-angular/loading';

@NgModule({
  declarations: [
    DSTClickOutsideDirective,
    DragAndDropUploadDirective,
    GtBusSearchDirective,
    GtBusinessAssignTreeDirective,
    GtBusinessAssignMenuComponent
  ],
  imports: [CommonModule, FormsModule, LoadingModule ],
  exports: [
    DSTClickOutsideDirective,
    DragAndDropUploadDirective,
    GtBusSearchDirective,
    GtBusinessAssignTreeDirective,
    GtBusinessAssignMenuComponent
  ]
})
export class SharedDirectivesModule {
}
