  <!--- Start Header --->
  <div class="modal-header">
    <h3 class="modal-title"> {{title}} <i (click)="cancel()" class="fa fa-times pull-right"></i>
      <i class="fa pull-right" [ngClass]="{'fa-plus-square-o': minimise, 'fa-minus-square-o': !minimise}" style= "margin-right: 10px" (click)="minimise=!minimise"></i>
    </h3>
  </div>
  <!--- End Header --->
  <hr>
  <!--- Start Body-->
  <div class="panel-body">
    <div class="container-border" style="padding: 10px; height: 354px;">
        <div class="taskActivityOverlay" *ngIf="showOverlayScreen">
            <div class="taskActivitySpinner">
                <i class="fa fa-spinner fa-spin"></i>
            </div>
        </div>
        <div class="panel-body taskActivityInnerBlock">
            <div class=" tasksPanel">
                <div class="col-border">
                    <div class="col-md-3" *ngFor="let item of templatesGroup | keyvalue">
                        <div class="template-group"> {{getGroupName(item.key)}} </div>
                        <!-- ng-repeat="template in value  | filter: searchText"  -->
                        <div class="keyList" [ngClass]="{'selected': taskModel === template}"
                            *ngFor="let template of $any(item).value; let i=index" [(ngModel)]="selectedTemplate"
                            (click)=" taskModel = template">
                            <input type="radio" style="margin-right: 4px;" name="{{template.TEMPLATE_GROUP_KEY}}"
                                id="{{template.TEMPLATE_KEY}}" [(ngModel)]="selectedTemplates[template.TEMPLATE_GROUP_KEY]"
                                [value]="template.TEMPLATE_KEY"
                                [disabled]="template.disable">{{template.TEMPLATE_NAME}}
    
                        </div>
                    </div>
    
                    <div class="templateLoader" modal-loader loading="showTemplateLoader"></div>
                </div>
            </div>
        </div>
    </div>        
           
  </div>
  <!--- End  Body --->

  <!--- Start  Footer --->
  <div class="modal-footer" *ngIf="!loading" uib-collapse="minimise">
      <button class="btn btn-info btn-sm" type="button" (click)="saveTemplates()">Save</button>

      <button class="btn btn-warning btn-sm" type="button"  (click)="cancel()">Cancel</button>
  </div>
  <!--- End  Footer --->
