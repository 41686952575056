import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdminService } from '../admin.service';
import { GtwSnackbarService } from '../../../shared/_services/gtw-snackbar.service';

interface PdfPushInput {
  baseUrl: string;
  ssoId: string;
  clientKey: string;
  taxYear: string;
  dcnId: string;
  submissionId: string;  
  gridData: string;
  modalName:string;
}

@Component({
  selector: 'gtw-pdf-push-to-efile',
  templateUrl: './pdf-push-to-efile.component.html',
  styleUrls: ['./pdf-push-to-efile.component.scss']
})
export class PdfPushToEfileComponent implements OnInit {

  constructor(private adminService : AdminService,
    private AlertService : GtwSnackbarService,
  ) { }
  
  @Output('cancel-pdf-push-confirmation') cancelPdfPushConfirmation: EventEmitter<any> = new EventEmitter<any>();
  @Input('pdfPushInput')
  set setPdfPushInput(pdfPushInput: string) {
    try{
      this.pdfPushInput = JSON.parse(pdfPushInput);
      if (this.pdfPushInput.modalName !== this.actionNm){
        this.pushData = true;
      }else{
        this.pushData = false;
      }
    }catch(error){
      console.log(error);
    }
   
  }
  
  loading: boolean  = false;
  taxYear : any;
  dcnId : any;
  submissionId : any;
  fileName : any;
  statementId : any;
  pushData : boolean = false;
  actionNm : any = 'delete-dst-pdf';



  pdfPushInput : PdfPushInput = {
    baseUrl: '/admin',
    ssoId: '000000000',
    clientKey: '0',
    taxYear : '2022',
    dcnId: '501',
    submissionId : '12205',
    gridData: '[{"statementId":2000067,"formId":268,"fileName":"form1099c"},{"statementId":2000068,"formId":268,"fileName":"form1099c"}]',
    modalName: 'test' //
  }

  ngOnInit(): void {
    if (this.pdfPushInput.modalName !== this.actionNm){
      this.pushData = true;
    }else{
      this.pushData = false;
    }
  }
  
  pushPdfToEfile():void {
    this.loading = true;
    this.adminService.uploadPdfToEfile(this.pdfPushInput.taxYear, this.pdfPushInput.dcnId, this.pdfPushInput.submissionId,this.pdfPushInput.gridData).toPromise().then(
      (response : any) => {
          if (response && response.callSuccess === '1') {
              this.cancelPdfPushConfirmation.emit();
              this.AlertService.openSnackBar('PDF Uploaded to Efile Successfully!!', 'gtw-snackbar--green');
              this.loading = false;
          } else {
              this.cancelPdfPushConfirmation.emit();
              throw new Error(response.errorMessage);
          }
      })
      .catch((err : any) => {
          this.cancelPdfPushConfirmation.emit();
          this.AlertService.openSnackBar(err.message === undefined ?'Something went wrong, please try again!': err.message, 'gtw-snackbar--red');
          this.loading = false;
      }
  );
  
  }

  cancel () : void {
    this.cancelPdfPushConfirmation.emit();
  };

  deleteForm  ():void {
    this.loading = true;
    this.adminService.deleteForm(this.pdfPushInput.taxYear, this.pdfPushInput.dcnId, this.pdfPushInput.submissionId,this.pdfPushInput.gridData).toPromise().then(
        (data) => {
            if (data && data.callSuccess === '1') {
                this.cancelPdfPushConfirmation.emit();
                this.AlertService.openSnackBar('Form Deleted Successfully!', 'gtw-snackbar--green');
                this.loading = false;
                
                
            } else {
                throw new Error(data.errorMessage);
                this.cancelPdfPushConfirmation.emit();
            }
        })
        .catch((err : any) => {
            this.cancelPdfPushConfirmation.emit();
            this.AlertService.openSnackBar(err.message === undefined ?'Something went wrong, please try again!' : err.message, 'gtw-snackbar--red');
            this.loading = false;
        }
    );
  }


}
