import { Component, Input } from '@angular/core';
import _ from 'lodash';

@Component({
    selector: 'app-gt-bus-search',
    template: `
    <div class="search-input">
      <i class="fa fa-search search"></i>
      <input
        placeholder="filter businesses"
        [(ngModel)]="filterData"
        (keyup)="filterMenu()"
        type="text"
      />
      <i class="fa fa-times close-x" (click)="resetFilter()"></i>
    </div>
  `,
    //   styleUrls: ['./gt-bus-search.component.css'], // Add your CSS file path
})
export class GtBusSearchDirective {
    @Input() menuData: any;

    filterData: any;
    collapsed: boolean = true;

    constructor() { }

    filterMenu() {
        const searchString = this.filterData;
        this.setTreeAttribute(this.menuData, 'collapsed', 'children', false);
        this.setTreeAttribute(this.menuData, 'hidden', 'children', false);
        this.filterTree(this.menuData, searchString, 'name', 'children');

        if (searchString === "" && this.collapsed) {
            this.setTreeAttribute(this.menuData, 'collapsed', 'children', true);
        }
    }

    resetFilter() {
        this.filterData = '';
        this.filterMenu();
    }

    // Replace this with your actual filtering logic
    filterMenuData(data: any, searchString: string): any {
        // Implement your filtering logic here
        return data;
    }

    setTreeAttribute(_collection: string | any[],_predicate: string,_children: string,value: boolean){
        for (let i = 0; i < _collection.length; i++) {
            let  n = _collection[i][_predicate];
            let children = _collection[i][_children];
            if(children && children.length ){
                this.setTreeAttribute(children,_predicate,_children,value )
            }
            _collection[i][_predicate] = value;
        }
    }

    filterTree(collection: string | any[], value: string, predicate: string, children: string) {
        for (let i = 0; i < collection.length; i++) {
            let  n = collection[i][predicate];
            let checkChildren = true;
            if(n){
                if(n && _.includes(n.toLowerCase(), value.toLowerCase()) ){
                    collection[i].hidden=false;
                    checkChildren = false;
                }else{
                    collection[i].hidden=true;
                }
                if (checkChildren && collection[i][children] && collection[i][children].length  ) {
                    this.filterTree(collection[i][children], value, predicate, children )
                    let parentHidden = true;
                    let innerChildren = collection[i][children];
                    if(innerChildren.length ){
                        for (let p = 0; p < innerChildren.length; p++) {
                            if( !innerChildren[p].hidden ){
                                parentHidden = false;
                            }
                        }
                    }

                    if( checkChildren  ){
                        collection[i].hidden = parentHidden;
                    }
                }
            } //end  if( n  )
        }//end for
    };//end filterTree
}
