import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActionService } from '../../shared/_services/action.service';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActionResponse } from '../../shared/_models/action-response.model';
import { DSTSharedService } from '../shared/dst-shared.service';
import { GtwElementsBaseUrlsModel } from '../../shared/_models/gtw-elements-base-urls.model';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  
  baseURLs!: GtwElementsBaseUrlsModel;
  extractedData: { statementId: number; formId: number; fileName: string; pdfComments: string; }[] | undefined; 

  constructor(private httpClient: HttpClient,
    private actionService: ActionService,
    private dstSharedService: DSTSharedService,
    ) { 

      this.baseURLs = {
        api: '/gtw',
        custom: '/custom',
        admin: '/admin',
        efile: '/gtw-efile-api',
        pdfEngine: '/pdf-engine',
        bulkPdfEngine: '/bulk-pdf-engine'
      };
      
      
    }

    refreshDSTDataOnEfile(taxYear: any, dcnId: any, submissionId: any) {
    
    const params = new HttpParams({
      fromObject: {
        taxYear: taxYear,
        dcnId: dcnId,
        submissionId: submissionId
      }
    });
    const url = `${this.baseURLs.admin}/dstDataToEfile`;
    const formData: FormData = new FormData();
    formData.append('taxYear', taxYear);
    formData.append('dcnId', dcnId);
    formData.append('submissionId', submissionId);
    return this.httpClient.post<any>(url, formData, {withCredentials: true, params: params}).pipe(
      map((data: ActionResponse) => {
        if (data.callSuccess !== '1') {
          throw Error(data.errorMessage);
        } else {
          return data;
        }
      })
    );
  }

  uploadPdfToEfile(taxYear: any, dcnId: any, submissionId: any, gridData: any) {
    const params = new HttpParams({
      fromObject: {
        taxYear: taxYear,
        dcnId: dcnId,
        submissionId: submissionId
      }
    });
    const url = `${this.baseURLs.admin}/dstPDFDataToEfile`;
    const formData: FormData = new FormData();
    formData.append('gridselData', gridData);
    return this.httpClient.post<any>(url, formData, {withCredentials: true, params: params}).pipe(
      map((data: ActionResponse) => {
        if (data.callSuccess !== '1') {
          throw Error(data.errorMessage);
        } else {
          return data;
        }
      })
    );

  }
  
  

  deleteForm (taxYear: any, dcnId: any, submissionId: any, gridData: any) {

    const params = new HttpParams({
      fromObject: {
        taxYear: taxYear,
        dcnId: dcnId,
        submissionId: submissionId
      }
    });
    const url = `${this.baseURLs.admin}/dstDeletePDFDataFromEfile`;
    const formData: FormData = new FormData();
    formData.append('gridselData', gridData);
    return this.httpClient.post<any>(url, formData, {withCredentials: true, params: params}).pipe(
      map((data: ActionResponse) => {
        if (data.callSuccess !== '1') {
          throw Error(data.errorMessage);
        } else {
          return data;
        }
      })
    );
  }

  setBaseURLs(baseURLs: GtwElementsBaseUrlsModel): void {
    this.baseURLs = baseURLs;
  }

}
