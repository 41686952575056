import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
//import { AppService } from 'projects/gtw-elements/src/app/app.service';

@Component({
  selector: 'gtw-business-assign-request-approval',
  templateUrl: './business-assign-request-approval.component.html',
  styleUrls: ['./business-assign-request-approval.component.scss']
})
export class BusinessAssignRequestApprovalComponent implements OnInit {
  title: any;
  rowData: any = {
    SSO_ID: ''
  };
  minimise: any;
  requestApprovalScreen: any;
  index: any;

  constructor(private dialog: MatDialog, 
    //private appService: AppService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.rowData.SSO_ID = data.globalService.userSettingsData.user.sso_id;
      this.requestApprovalScreen = true;
      this.index = data.index;
   }

  ngOnInit(): void {
    this.title = "Assign Business";
  }

  cancel(): void {
    this.dialog.closeAll();

  }
  
  eventType(type: any): void {
    if(type == 'cancel') {
      this.cancel();
    } else {
      this.title = type;
    }
  }
}
