import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from 'projects/gtw-elements/src/environments/environment';
import { GtwSnackbarService } from '../shared/_services/gtw-snackbar.service';
import { UtilityService } from '../shared/_services/utility.service';

@Injectable({
  providedIn: 'root',
})
export class UserAdminService {
  constructor(
    private http: HttpClient,
    private alertService: GtwSnackbarService,
    private AlertService: GtwSnackbarService,
    private utilityService: UtilityService
  ) {}

  // Define your URLs here
  // private readonly URLs = {
  //   updateUser: `${environment.admin_api_url}/adminUpdateUser`,
  //   getUserAssign: `${environment.admin_api_url}/getUserAssign`,
  //   setUserAccess: `${environment.admin_api_url}/setUserAccess`,
  //   setUserAccessAll: `${environment.admin_api_url}/setAllUserAccess`,
  //   findUser: `${environment.admin_api_url}/getUserList`,
  //   getUserDetails: `${environment.admin_api_url}/getUserDetails`,
  //   addUser: `${environment.admin_api_url}/addNewUser`,
  //   removeAllAccessURL: `${environment.base_url}/loadJsonObject?action_code=qfeqhn`,
  //   fetchClients: `${environment.admin_api_url}/fetchClients`,
  //   FETCHUSERCLIENTS: environment.admin_api_url + '/fetchUserClients',
  //   getAllActiveBusinessesURL: `${environment.base_url}/loadJsonObject?action_code=8g8maf`,
  //   getJurisByClientKeyURL: `${environment.base_url}/loadJsonObject?action_code=x5hvpy`,
  //   getTemplatesByJurisByClientKeyURL: `${environment.base_url}/loadJsonObject?action_code=p6bn0u`,
  //   validateUserTeamplatesModsURL: `${environment.base_url}/saveJsonObject`,
  //   applyUserTemplateModulesURL: `${environment.base_url}/loadJsonObject`,
  //   getUserTemplateDetailsBySsoURL: `${environment.base_url}/loadJsonObject?action_code=9et55j`,
  //   getDiffUserTemplateDetURL: `${environment.admin_api_url}/getDiffUserAndTemplateAssign`,
  //   getBizzHeirarchy: '/getBizzHierarchy',
  //   saveBizHeirarchy: `${environment.base_url}/saveBizHeirarchy`,
  //   startNotificationEngine: `${environment.base_url}/notification/addNewUser`, // Remove this before delivering to production
  //   notMathedTaskList: `${environment.base_url}/nonMatchTaskList`,
  //   getAuditData: `${environment.admin_api_url}/getUserAudit`,
  //   getUserList: `${environment.base_url}/loadGridJson?action_id=40&grid_id=3`,
  // };

  private readonly URLs = {
    updateUser: '/adminUpdateUser',
    getUserAssign: '/getUserAssign',
    setUserAccess: '/setUserAccess',
    setUserAccessAll: '/setAllUserAccess',
    findUser: '/getUserList',
    getUserDetails: '/getUserDetails',
    addUser: '/addNewUser',
    removeAllAccessURL: '/loadJsonObject?action_code=qfeqhn',
    fetchClients: '/fetchClients',
    FETCHUSERCLIENTS: '/fetchUserClients',
    getAllActiveBusinessesURL: '/loadJsonObject?action_code=8g8maf',
    getJurisByClientKeyURL: '/loadJsonObject?action_code=x5hvpy',
    getTemplatesByJurisByClientKeyURL: '/loadJsonObject?action_code=p6bn0u',
    validateUserTeamplatesModsURL: '/saveJsonObject',
    applyUserTemplateModulesURL: '/loadJsonObject',
    getUserTemplateDetailsBySsoURL: '/loadJsonObject?action_code=9et55j',
    getDiffUserTemplateDetURL: '/getDiffUserAndTemplateAssign',
    getBizzHeirarchy: '/getBizzHierarchy',
    saveBizHeirarchy: '/saveBizHeirarchy',
    startNotificationEngine: '/notification/addNewUser', // Remove this before delivering to production
    notMathedTaskList: '/nonMatchTaskList',
    getAuditData: '/getUserAudit',
    getUserList: '/loadGridJson?action_id=40&grid_id=3',
  };

  nonMatchedTaskList(data: any): any {
    this.callService(this.URLs.notMathedTaskList, data);
  }

  getBizzHierarchyBySsoByGroupObjKey(baseUrl: any, data: any): any {
    return this.http.post(baseUrl.admin + this.URLs.getBizzHeirarchy, data);
  }

  updateUser(data: any): any {
    return this.http.post(this.URLs.updateUser, data);
  }

  removeAllAccess(data: any): any {
    this.callService(this.URLs.removeAllAccessURL, data);
  }

  findUser(data: any): any {
    return this.http.post(this.URLs.findUser, data);
  }

  // Remove this method before delivering to production
  startNotificationEngine(data: any): any {
    this.callService(this.URLs.startNotificationEngine, data);
  }

  addUser(data: any): any {
    //this.callService(this.URLs.addUser, data);
    return this.http.post(this.URLs.addUser, data);
  }

  getUserDetails(data: any): any {
    return this.http.post(this.URLs.findUser, data);
  }

  getAllUserList(data: any): any {
    return this.http.post(this.URLs.getUserList, data);
  }

  getUserClients(params: any): any {
    return this.http.post(this.URLs.FETCHUSERCLIENTS, params);
  }
  /*  loadUserClients(data: any) {
    this.http.post(this.URLs.FETCHUSERCLIENTS, data);
  }
 */
  getUserAssign(data: any): any {
    return this.http.post(this.URLs.getUserAssign, data);
  }

  setAccess(node: any, user_id: any, rowPrimaryKey: any, client_key: any): any {
    const params = {
      jcd_ta_key: node.jcd_ta_key,
      jcd_ta_role_key: node.role.value,
      user_id: user_id,
      client_key: client_key,
    };

    this.callService(this.URLs.setUserAccess, this.utilityService.convertToFormData(params));
  }

  setAccessAll(
    nodes: any,
    user_id: any,
    rowPrimaryKey: any,
    client_key: any
  ): any {
    this.callService(
      this.URLs.setUserAccessAll,
      this.utilityService.convertToFormData({ user_access_list: nodes })
    );
  }

  getAllActiveBusinesses(data: any): any {
    return this.http.post(this.URLs.getAllActiveBusinessesURL, data);
  }

  getJurisByBusiness(data: any): any {
    return this.http.post(this.URLs.getJurisByClientKeyURL, data);
  }

  getTemplatesByJAndClient(data: any, action_code: string): any {
    return this.http.post(this.URLs.getTemplatesByJurisByClientKeyURL, data);
  }

  getUserTemplateDetails(data: any): any {
    return this.http.post(this.URLs.getUserTemplateDetailsBySsoURL, data);
  }

  getDiffUserTemplateDetails(data: any): any {
    this.callService(this.URLs.getDiffUserTemplateDetURL, data);
  }

  validateUserTeamplatesMods(data: any): any {
    this.callService(this.URLs.validateUserTeamplatesModsURL, data);
  }

  applyUserTemplateMods(data: any): any {
    console.log('applyUserTemplateMods() data ::', data);
    this.callService(this.URLs.applyUserTemplateModulesURL, data);
  }

  // Method for getting user entity (not yet converted)
  getUserEntity(data: any): any {
    this.callService('app/json/entity-list.json', data);
  }

  // Method for saving business hierarchy (not yet converted)
  saveBizHeirarchy(data: any): any {
    this.callService('/saveBizHeirarchy', data);
  }

  // Method for getting user audit data
  getAuditData(auditType: string, userSso: string, clientKey: string): any {
    const url = `${this.URLs.getAuditData}?audit_type=${encodeURIComponent(
      auditType
    )}&user_sso=${userSso}&client_key=${clientKey}`;
    return this.http.get(url);
  }

  saveMultiTempUser(
    clob_data: any,
    clientKey: any,
    globalParams: { tax_year: any; scenario: any; jcd_key: any }
  ) {
    let params: any = {
      clientKey: clientKey,
      tax_year: globalParams.tax_year,
      scenario: globalParams.scenario,
      jcd_key: globalParams.jcd_key,
      clob_data: JSON.stringify(clob_data),
    };
    let fd = new FormData();
    for (const key in params) {
      fd.append(key, params[key]);
    }
    return this.http.post('/saveJsonObject?action_code=9wru7p',
      fd
    );
  }

  userAssignedList(data: any, url: string): any {
    return this.http.post(url, this.utilityService.convertToFormData(data));
  }

  callService(url: string, params: FormData) {
    return this.http.post(url, params).subscribe(
      (data: any) => {
        return this.handleSuccess(data);
      },
      catchError((error) => {
        this.handleError(error);
        throw error;
      })
    );
  }

  handleSuccess(response: any) {
    if (response.callSuccess === '1') {
      this.AlertService.openSnackBar(
        'Data saved successfully.', 'gtw-snackbar--green'
      );
      return response;
    } else {
      if (response.errorMessage === 'no access') {
        this.AlertService.openSnackBar(
          'Sorry you do not have access to do the requested action.', 'gtw-snackbar--red'
        );
      } else {
        this.AlertService.openSnackBar(
          'An error has occurred, If you continue to experience this issue please visit the help page to contact support.', 'gtw-snackbar--red'
        );
      }
    }
  }

  private handleError(error: any): void {
    console.error('An error occurred:', error);
    // Handle error and show appropriate alerts here using the AlertService
    this.alertService.openSnackBar(
      'An error occurred. Please try again later.', 'gtw-snackbar--red'
    );
  }
}
