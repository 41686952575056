import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  	constructor() { }
	
	convertToFormData(data: any): FormData {
		const formData = new FormData();
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				formData.append(key, data[key]);
			}
		}
		return formData;
	}

}
