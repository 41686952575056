
<div class="container-fluid gt-user-details">

    <mat-tab-group mat-align-tabs="start" #tabGroup>
        <mat-tab label="Client Approval Requests">
            <div class="row" style="margin-top: 20px;">
                <!-- <span class="fa fa-pencil-square-o" (click)="assignTemplates()" style="cursor: pointer;"></span> -->
        
                <div class="col-md-12">
                    <div class="panel panel-default">
                        <!-- <div class="panel-heading" style="padding-left: 5px; font-weight: 700;">Client Approval Requests 
                            <span>
                                <button class="btn btn-primary btn-sm" style="float: right;" type="button" (click)="viewHistory()">View History</button>
                            </span>
                        </div> -->
                        <div class="panel-heading" style="padding-left: 5px; font-weight: 700;">Client Approval Requests <span class="fa fa-refresh refreshIcon" (click)="getPendingRequestsData()"></span></div>
                        <div class="panel-body" style="padding: 2px;height: 500px;overflow-y: auto;overflow-x: hidden;">
                            <table class="gt-client table table-bordered table-striped table-condensed"
                                style="margin-bottom: 0px">
                                <thead>
                                    <tr>
                                        <th style="">Requested By</th>
                                        <th style="">Client</th>
                                        <th style="">Role</th>
                                        <!-- <th>Status</th> -->
                                        <th>Approver</th>
                                        <th>Business Assign</th>
                                        <th>Template Assign</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of pendingRequests; let i = index"
                                    [ngClass]="{'active-client': item.active && item.user_type_key !== null}">
                                    <td>
                                        {{item.DISPLAY_NAME}}
                                    </td>
                                    <td>{{ item.CLIENT_NAME }}</td>
                                    <td>
                                        <!-- {{item.REQUESTED_USER_TYPE}} -->
                                        <select required class="input-sm" name="user_type"
                                            [(ngModel)]="item.REQUESTED_USER_TYPE" style=" padding: 0px; border: 1px solid #ccc">
                                            <option *ngFor="let s of user_types" [value]="s.label">{{ s.label }}</option>
                                        </select>
                                    </td>
                                    <!-- <td>
                                        {{item.REQUEST_STATUS}}
                                    </td> -->
                                    <td>
                                        {{user_name}}
                                    </td>
                                    <td style="text-align: center;">
                                        <span class="fa fa-pencil" style="cursor: pointer;" (click)="assignBusiness(i)"></span>
                                    </td>
                                    <td style="text-align: center;">
                                        <span class="fa fa-pencil-square-o" (click)="assignTemplates(i, item)" style="cursor: pointer;"></span>
                                    </td>
                                    <td>
                                        <div style="text-align: center;">
                                            <button class="btn btn-success btn-sm" type="button" (click)="approveRequest(i, item)">Approve</button>
                                            <button type="button" class="btn btn-danger btn-sm" (click)="rejectRequest(i, item)" style="margin-left: 10px;">Reject</button>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                                
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>

        <mat-tab label="Request Access Log">
            <gtw-view-request-history 
            [header-data]="globalService"> 
            </gtw-view-request-history>
        </mat-tab>

    </mat-tab-group>

</div>