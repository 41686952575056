import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdminService } from '../admin.service';
import { GtwSnackbarService } from '../../../shared/_services/gtw-snackbar.service';

interface DataPushInput {
  baseUrl: string;
  ssoId: string;
  clientKey: string;
  taxYear: any;
  dcnId: any;
  submissionId: any;
}


@Component({
  selector: 'gtw-data-push-to-efile',
  templateUrl: './data-push-to-efile.component.html',
  styleUrls: ['./data-push-to-efile.component.scss']
})


export class DataPushToEfileComponent implements OnInit {
  
  constructor(private adminService : AdminService,
    private AlertService : GtwSnackbarService,
  ) { }
  
  @Output('cancel-data-push-confirmation') cancelDataPushConfirmation: EventEmitter<any> = new EventEmitter<any>();
  @Input('dataPushInput')
  set setDataPushInput(dataPushInput: string) {
    try{
      this.dataPushInput = JSON.parse(dataPushInput);
    }catch(error){
      console.log(error);
    }
    
  }
  
  loading: boolean  = false;
  taxYear : any;
  dcnId : any;
  submissionId : any;

  dataPushInput : DataPushInput = {
    baseUrl: '/admin',
    ssoId: '000000000',
    clientKey: '0',
    taxYear : 2022,
    dcnId: 501,
    submissionId : 12205
  }

  ngOnInit(): void {

  }

  refresh():void {
    this.loading = true;
    this.AlertService.openSnackBar('Data Pushed to Efile is initiated...', 'gtw-snackbar--green');
    this.cancelDataPushConfirmation.emit();
    this.adminService.refreshDSTDataOnEfile(this.dataPushInput.taxYear, this.dataPushInput.dcnId, this.dataPushInput.submissionId).toPromise().then(
      (response : any) => {
          if (response && response.callSuccess === '1') {
              this.AlertService.openSnackBar('Data Pushed to Efile Successfully!', 'gtw-snackbar--green');
              this.loading = false;
              
          } else {
              this.cancelDataPushConfirmation.emit();
              throw new Error(response.errorMessage);
             
          }
      })
      .catch((err : any) => {
          this.cancelDataPushConfirmation.emit();
          this.AlertService.openSnackBar(err.message === undefined ?'Something went wrong, please try again!': err.message, 'gtw-snackbar--red');
          this.loading = false;
          
      }
  );
  
  }

  cancel () : void {
    this.cancelDataPushConfirmation.emit();
  };

}
