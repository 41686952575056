import { Component, Input } from '@angular/core';
import * as _ from 'lodash';
import { GtwSnackbarService } from '../_services/gtw-snackbar.service';
import { AdminSharedService } from '../../admin/shared/admin-shared.service';

@Component({
  selector: 'gt-business-assign-menu',
  template: `
    <div class="gt-dark-header" id="gt-bus-assign" *ngIf="!loading">
      <div class="pull-left">
        <app-gt-bus-search [menuData]="menuData"></app-gt-bus-search>
      </div>
      <div class="pull-right">
        <div *ngIf="renderingMode === 0" (click)="assignAll()" class="btn btn-default btn-xs" style="margin-right:10px"> Assign All</div>
        <div *ngIf="renderingMode === 0" (click)="unAssignAll()" class="btn btn-default btn-xs" style="margin-right:30px"> Unassign All</div>
        <div *ngIf="renderingMode === 2" (click)="finalizeAsIs()" class="btn btn-default btn-xs" style="margin-right:10px">Finalize</div>
        <div *ngIf="renderingMode === 2" (click)="override()" [hidden]="true" class="btn btn-default btn-xs" style="margin-right:10px">Override</div>
        <div *ngIf="renderingMode === 2" (click)="discard()" [hidden]="true" class="btn btn-default btn-xs" style="margin-right:10px">Discard</div>
        <i (click)="toggleAll(true)" class="fa fa-folder"></i>
        <i (click)="toggleAll(false)" class="fa fa-folder-open "></i>
      </div>
    </div>
    <ap-loading id="overlay" *ngIf="loading" [size]="'24px'" [colorName]="'a-primary'">
    </ap-loading>
    <div>
    // [slimScroll]="slimScrollOptions"
      <div style="padding-right:10px">
        <app-gt-business-assign-tree [menuData]="menuData"></app-gt-business-assign-tree>
      </div>
    </div>
  `,
  // styleUrls: ['./gt-business-assign-menu.component.css'], // Add your CSS file path
})
export class GtBusinessAssignMenuComponent {
  @Input()
  menuData!: any[];
  @Input() userId: any;
  @Input() loading: any;
  @Input() renderingMode: any;
  @Input() taxYear: any;
  @Input() reportingMonth: any;
  @Input() clientKey: any;
  @Input() groupObjKey: any;
  @Input() callBackService: any;

  collapsed: boolean = true;

  constructor(private AlertService: GtwSnackbarService, private adminService: AdminSharedService) { }

  slimScrollOptions = {
    height: '400px',
    color: '#5cd6fb',
    railColor: '#000000',
    railVisible: true,
    railOpacity: 100,
    distance: '2px',
    alwaysVisible: true,
  };

  ngOnInit() {
    this.callBackService = this.callBackService;
    this.setTreeAttribute(this.menuData, 'collapsed', 'children', true);
    this.checkAllParents();
    this.countActiveNodes();
  }

  setTreeAttribute(collection: any[], predicate: string, children: string, value: boolean) {
    for (let i = 0; i < collection.length; i++) {
      const n = collection[i][predicate];
      const childNodes = collection[i][children];
      if (childNodes && childNodes.length) {
        this.setTreeAttribute(childNodes, predicate, children, value);
      }
      collection[i][predicate] = value;
    }
  }

  getScrollHeight() {
    const top = document.getElementById('gt-bus-assign')?.getBoundingClientRect().top || 0;
    return window.innerHeight - (top + 40);
  }

  toggleAll(val: boolean) {
    this.setTreeAttribute(this.menuData, 'collapsed', 'children', val);
    this.collapsed = val;
  }

  toggleFolder(node: any) {
    node.collapsed = !node.collapsed;
  }

  assignAll() {
    this.setTreeAttribute(this.menuData, 'selected', 'children', true);
    this.countActiveNodes();
  }

  finalizeAsIs() {
    console.log('Finalizing it now... wait');
    const data = {
      sso_id: this.userId,
      taxyear: this.taxYear,
      rpt_mth: this.reportingMonth,
      client_key: this.clientKey,
      grp_obj_key: this.groupObjKey,
    };

    this.loading = true;
    this.adminService.finalizeStagedBizHierarchy(data).subscribe(
      (data: { callSuccess: string; errorMessage: string; }) => {
        this.loading = false;
        const callStatus = parseInt(data.callSuccess);

        if (callStatus === 1) {
          console.log('data', data);
          this.AlertService.openSnackBar(`Business Hierarchy Staged Data Applied Successfully to Tax Year: ${this.taxYear} Group Obj Key: ${this.groupObjKey}`, 'gtw-snackbar--success');
        } else if (callStatus < 0) {
          console.log('data', data);
          this.AlertService.openSnackBar(`Failed Business Hierarchy Finalization at STEP: ${callStatus} for Tax Year: ${this.taxYear} Group Obj Key: ${this.groupObjKey}`, 'gtw-snackbar--red');
        } else {
          if (data.errorMessage === 'no access') {
            this.AlertService.openSnackBar('Sorry, you do not have access to do the requested action.', 'gtw-snackbar--red');
          } else {
            this.AlertService.openSnackBar('An error has occurred. If you continue to experience this issue, please visit the help page to contact support.', 'gtw-snackbar--red');
          }
        }
      },
      (error: any) => {
        this.loading = false;
        console.error('Error:', error);
        this.AlertService.openSnackBar('An error occurred while finalizing. Please try again later.', 'gtw-snackbar--red');
      }
    );
  }

  override() {
    console.log('Not enabled yet...');
    alert('This functionality is not available yet..');
  }

  discard() {
    console.log('Discarding all....');
  }

  unAssignAll() {
    this.setTreeAttribute(this.menuData, 'selected', 'children', false);
    this.countActiveNodes();
  }

  selectAll(node: any, value: boolean) {
    this.setTreeAttribute(this.menuData, 'selected', 'children', value);
  }

  selectBranch(node: any, value: boolean) {
    this.setTreeAttribute(node.children, 'selected', 'children', value);
  }

  countActiveNodes() {
    _.forEach(this.menuData, (value) => {
      value.numSelected = this.countNodes(value);
    });
  }

  parentAllCheck(node: any) {
    let allChecked = true;
    const parent = node.parent;

    if (parent && parent.children) {
      _.forEach(parent.children, (value) => {
        if (!value.selected) {
          allChecked = false;
        }
      });
      parent.selected = allChecked ? true : false;

      if (parent.parent) {
        this.parentAllCheck(parent);
      }
    }
  }

  filterTree(collection: any[], value: string, predicate: string, children: string) {
    for (let i = 0; i < collection.length; i++) {
      const n = collection[i][predicate];
      let checkChildren = true;
      if (n) {
        if (n && _.includes(n.toLowerCase(), value.toLowerCase())) {
          collection[i].hidden = false;
          checkChildren = false;
        } else {
          collection[i].hidden = true;
        }
        if (checkChildren && collection[i][children] && collection[i][children].length) {
          this.filterTree(collection[i][children], value, predicate, children);
          let parentHidden = true;
          const innerChildren = collection[i][children];
          if (innerChildren.length) {
            for (let p = 0; p < innerChildren.length; p++) {
              if (!innerChildren[p].hidden) {
                parentHidden = false;
              }
            }
          }
          if (checkChildren) {
            collection[i].hidden = parentHidden;
          }
        }
      } // end if( n )
    } // end for
  }

  removeTreeAttribute(collection: any[], predicate: string, children: string) {
    for (let i = 0; i < collection.length; i++) {
      const n = collection[i][predicate];
      const childNodes = collection[i][children];
      if (childNodes && childNodes.length) {
        this.removeTreeAttribute(childNodes, predicate, children);
      }
      if (n && n === predicate) {
        collection[i][predicate] = undefined;
      }
    }
  }

  countNodes(node: any) {
    let selectedNum = 0;

    function countChildren(node: any) {
      let nodeCount = 0;
      _.forEach(node.children, (value) => {
        if (value.children) {
          nodeCount += countChildren(value);
        } else if (value.selected) {
          ++selectedNum;
          ++nodeCount;
        }
      });

      node.numSelected = nodeCount;
      return nodeCount;
    }

    countChildren(node);
    return selectedNum;
  }

  checkAllParents() {
    _.forEach(this.menuData, (value) => {
      this.topAllCheck(value);
    });
  }

  topAllCheck(node: any) {
    let allChecked = true;

    if (node.children) {
      _.forEach(node.children, (value) => {
        if (node.children) {
          this.topAllCheck(value);
        }
        if (!value.selected) {
          allChecked = false;
        }
      });
      node.selected = allChecked ? true : false;
    }
  }
}
