import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterObj',
    pure: false
})

export class FilterObjPipe implements PipeTransform {
    transform(items: any, args: any): any {
        if(args == "") {
            return items;
        }
        return items.filter((s: any) => {
            return s.GROUP_OBJ_KEY == args;
        })
        
    }
}