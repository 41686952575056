import {Injectable} from '@angular/core';
import {GtwElementsBaseUrlsModel} from '../../shared/_models/gtw-elements-base-urls.model';
import {Observable} from 'rxjs';
import {ActionResponse} from '../../shared/_models/action-response.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {ActionService} from '../../shared/_services/action.service';
import { GtwSnackbarService } from '../../shared/_services/gtw-snackbar.service';
@Injectable({
  providedIn: 'root'
})
export class AdminSharedService {

  clientKey!: number;
  gtwTaxYear!: string;
  gtwScenario!: string;
  baseURLs!: GtwElementsBaseUrlsModel;
  isSystemLock: boolean;
  isFiled: boolean;

  constructor(private httpClient: HttpClient,
              private actionService: ActionService,
              private alertService: GtwSnackbarService) {

    this.baseURLs = {
      api: '/gtw',
      custom: '/custom',
      admin: '/admin',
      efile: '/gtw-efile-api',
      pdfEngine: '/pdf-engine',
      bulkPdfEngine: ''
    };
    this.isSystemLock = false;
    this.isFiled = false;
  }

  setClientKey(clientKey: string): void {
    this.clientKey = Number.parseInt(clientKey);
  }

  setTaxYearScenario(taxYear: string, scenario: string): void {
    this.gtwTaxYear = taxYear;
    this.gtwScenario = scenario;
  }

  setBaseURLs(baseURLs: GtwElementsBaseUrlsModel): void {
    this.baseURLs = baseURLs;
  }

  finalizeStagedBizHierarchy(data: any): Observable<any> {
    console.log("Finalizing Staged Biz Hierarchy... Please wait... Data::", data);

    data.action_code = 'ewwww9';

    return this.httpClient
      .post('/gtw/loadJsonObject', data)
      .pipe(
        map((response: any) => {
          if (response.callSuccess === '1') {
            return response;
          } else {
            if (response.errorMessage === 'no access') {
              this.alertService.openSnackBar('Sorry, you do not have access to do the requested action.', 'gtw-snackbar--red');
            } else {
              this.alertService.openSnackBar('An error has occurred. If you continue to experience this issue, please visit the help page to contact support.', 'gtw-snackbar--red');
            }
            throw new Error('Request failed'); // You can handle this error further in your component if needed
          }
        }),
        ((error) => {
          // Handle HTTP errors here if needed
          throw error;
        })
      );
  }


}
