import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {Alert, DSTFormOption, StatementTrackerService} from './statement-tracker.service';
import {HttpErrorResponse} from '@angular/common/http';
import {DSTStatement} from '../shared/models/dst-statement.model';
import {ModalComponent} from 'appkit-angular/modal';
import {ProjectTaggingComponent} from '../shared/components/project-tagging/project-tagging.component';
import {DfMeDropdownComponent} from '../../shared/components/df-me-dropdown/df-me-dropdown.component';
import {SaveActionResponse} from '../../shared/_models/save-action-response.model';
import {CopyReplicateComponent} from './copy-replicate/copy-replicate.component';
import {TrackerTabsService, TrackerTabState} from './tracker-tabs.service';
import {DSTDynamicComponentResolverService, DSTSavePayload} from './create-disclosure/shared/dst-dynamic-component-resolver.service';
import {MatchTrackerComponent} from './match-tracker/match-tracker.component';
import {CreateOptionsComponent} from './create-options/create-options.component';
import {PtableColConfigModel} from '../shared/models/ptable-col-config.model';
import {Table} from 'primeng-lts/table';
import {StmtBtnActionPayload} from '../shared/components/stmt-id-btn/stmt-id-btn.component';
import {StmtIdBtnService} from '../shared/components/stmt-id-btn/stmt-id-btn.service';
import {DSTSharedService, DSTTableActionStatus} from '../shared/dst-shared.service';
import {DSTMarkAsOption} from '../shared/components/stmt-id-btn/dst-mark-as-template/dst-mark-as-template.component';
import {JSONExportHeader, JSONExportService} from '../../shared/_services/JSONExport.service';
import {DSTChildFormState} from '../shared/models/dst-child-form-state.model';
import {DSTGridFilter, DSTGridFilterVal} from '../shared/models/dst-grid-filter.model';
import {FormReivewPdf} from '../../shared/components/form-review-pdf/form-review-pdf.service';
import { CreateDisclosureSharedService } from './create-disclosure/shared/create-disclosure-shared.service';
import { MessageService } from '../shared/services/message.service';
import { MenuPosPayload } from '../shared/components/stmt-id-btn/smartMenuPos.directive';
import  _  from 'lodash';

@Component({
  selector: 'gtw-statement-tracker',
  templateUrl: './statement-tracker.component.html',
  styleUrls: ['../shared/styles/prime-grid-overrides.scss', './statement-tracker.component.scss']
})
export class StatementTrackerComponent implements OnInit, OnChanges, OnDestroy {

  // params to be passed from gtw-ui
  @Input('gtw-param') gtwInputParam!: string;
  // events dispatched and to be listened for in gtw-ui
  // @Output('createNew') showCreateDisclosure: EventEmitter<any>;
  @Output('show-data-loss-confirmation') showDataLossConfirmation: EventEmitter<any> = new EventEmitter<any>();
  @Output('is-child-statement-edit') isChildEditing: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('dt') table!: Table;
  @ViewChild('dstDeleteConfirmDialog') dstDeleteConfirmDialog!: ModalComponent;
  @ViewChild('dstMarkOtherReasonDialog') dstMarkOtherReasonDialog!: ModalComponent;
  @ViewChild('dstUnmatchConfirmDialog') dstUnmatchConfirmDialog!: ModalComponent;
  @ViewChild('pdfViewerModal') pdfViewerModal!: ModalComponent;
  @ViewChild('modalRef1') modalRef1!: ElementRef;
  @ViewChild('modalRef2') modalRef2!: ElementRef;
  @ViewChild('modalRef3') modalRef3!: ElementRef;
  @ViewChild(DfMeDropdownComponent) dfMeDropdownComponent!: DfMeDropdownComponent;
  @ViewChild(ProjectTaggingComponent) projectTaggingComponent!: ProjectTaggingComponent;
  @ViewChild(CopyReplicateComponent) copyRepComponent!: CopyReplicateComponent;
  @ViewChild(MatchTrackerComponent) matchTrackerComponent!: MatchTrackerComponent;
  @ViewChild(CreateOptionsComponent) createOptionsComponent!: CreateOptionsComponent;

  taxYears: any[] = [
    {
      data: [
        {
          id: 0,
          text: '2017'
        },
        {
          id: 1,
          text: '2018'
        },
        {
          id: 2,
          text: '2019'
        },
        {
          id: 3,
          text: '2020',
          selected: true
        },
        {
          id: 4,
          text: '2021'
        }
      ]
    }
  ];
  tabs: TrackerTabState[];
  activeTab!: TrackerTabState | null;
  createNewTabIsOpen!: boolean;
  tableData: DSTStatement[];
  tableDataFiltered: DSTStatement[];
  tableDataLoading: boolean = false;
  tableActionStatus: DSTTableActionStatus;
  filtersOptions: DSTGridFilter[];
  cols: PtableColConfigModel[] = [
    {
      field: 'TAXYEAR',
      header: 'Tax Year',
      width: '80px'
    },
    {
      field: 'businessName',
      header: 'Business /ME',
      width: '240px'
      // searchIn: ['businessName', 'meName'],
    },
    {
      field: 'disclosureShortName',
      header: 'Disclosure',
      width: '140px'
    },
    {
      field: 'creationSource',
      header: 'Creation Source',
      width: '250px'
      // hidden: true
    },
    {
      field: 'attachment_name',
      header: 'File Name',
      width: '300px'
      // hidden: true
    },
    {
      field: 'gtw_source_desc',
      header: 'GTW Source',
      width: '250px'
    },
    {
      field: 'associatedStatements',
      header: 'Associated Disclosure(s)',
      width: '200px'
    },
    {
      field: 'projectName',
      header: 'Project',
      width: '180px'
      // searchIn: ['projectName', 'projectStepName'],
      // className: 'th-project'
    },
    {
      field: 'dcnName',
      header: 'Filing Group',
      width: '315px'
    },
    {
      field: 'createdBy',
      header: 'User Activity',
      width: '380px'
      // searchIn: ['createdBy', 'createdOnFormatted', 'modifiedBy', 'modifiedFormatted'],
    },
    {
      field: 'pdfPreview',
      header: 'PDF Preview',
      width: '105px'
    },
  ];
  frozenCols: PtableColConfigModel[] = [
    {
      field: 'statementId',
      header: 'Statement ID',
      width: '150px'
    },
    {
      field: 'leId',
      header: 'Legal Entity',
      width: '300px'
    },
  ];
  filters: DSTGridFilter[]= [
    {
      filterId: 'leid',
      filterType: 'list',
      filterValues: [],
      render:((row: DSTStatement)=>row.leId + ' - ' +row.entityName),
      searchProp:'leId'
    },
    {
      filterId: 'tax_year',
      filterType: 'list',
      filterValues: [],
      render:((row: DSTStatement)=>row.TAXYEAR),
      searchProp:'TAXYEAR'
    },
    {
      filterId: 'project',
      filterType: 'list',
      filterValues: [],
      render:((row: DSTStatement)=>row.projectName),
      searchProp:'projectName'
    },
    {
      filterId: 'disclosure',
      filterType: 'list',
      filterValues: [],
      render:((row: DSTStatement)=>row.disclosureShortName),
      searchProp:'disclosureShortName'
    },
    {
      filterId: 'creationSrc',
      filterType: 'list',
      filterValues: [],
      render:((row: DSTStatement)=>row.creationSource),
      searchProp:'creationSource'
    }
  ]
  colFiltersChanged: boolean;
  markAsOptions!: DSTMarkAsOption[];
  markAsReason: string;
  activeRow!: DSTStatement;
  userClickedRow!: DSTStatement;
  scrollBarWidth: number;
  alert: Alert;
  showConfirmDeleteModal: boolean = false;
  showMarkOtherReasonModal: boolean = false;
  showConfirmUnMatchModal: boolean = false;
  showProjectTaggingModal: boolean = false;
  showMatchTrackerModal: boolean = false;
  showCopyRepModal: boolean = false;
  showOptionsModal: boolean = false;
  copyMode!: string;
  formOptions!: DSTFormOption[];
  showPDFViewerModal: boolean = false;
  stmtDetailsLoaded: boolean = false;
  dstProformaData: any;
  totalRecords: number = 0;
  exportingExcel: boolean = false;

  private onDisSaveSubs!: Subscription;
  private onDisCancelSubs!: Subscription;
  private taxYear: string;
  private selectedMes: string;
  ddRow!: DSTStatement;
  menuPos!: MenuPosPayload;
  ngPrimeTableEle!: HTMLElement | null;

  constructor(public dstSharedService: DSTSharedService,
              private sharedService: CreateDisclosureSharedService,
              private statementTrackerService: StatementTrackerService,
              private trackerTabsService: TrackerTabsService,
              private dCRService: DSTDynamicComponentResolverService,
              private stmtIdBtnService: StmtIdBtnService,
              private jsonExportService: JSONExportService,
              private changeDetectorRef: ChangeDetectorRef,
              private messageService: MessageService) {
    this.taxYear = this.taxYears[0].data[2].text;
    this.alert = dstSharedService.alert;
    this.selectedMes = '';
    this.tabs = this.trackerTabsService.trackerTabs;
    this.tableData = [];
    this.tableDataFiltered = [];
    this.colFiltersChanged = true;
    this.tableActionStatus = dstSharedService.tableActionStatus;
    this.filtersOptions = [];
    this.markAsReason = '';
    this.scrollBarWidth = 0;
    this.onDisSaveSubs = this.dCRService.onDisclosureSave.subscribe((payload: DSTSavePayload) => {
      this.statementTrackerService.refresh = true;
    });
    this.onDisCancelSubs = this.dCRService.onDisclosureCancel.subscribe(() => {
      const activeTab: TrackerTabState | null = this.trackerTabsService.getActiveTrackerTab();
      if (activeTab) {
        this.closeTab(activeTab.id);
      }
    });
  }

  ngOnInit(): void {
    // for local testing only
    // this.dstSharedService.setDSTUser('400368836', 5);
    // this.dstSharedService.setBaseURLs({
    //   api: '/gtw',
    //   custom: '/custom',
    //   admin: '/admin',
    //   efile: '/gtw-efile-api',
    //   pdfEngine: '/pdf-engine',
    //   bulkPdfEngine: '/bulk-pdf-engine'
    // });
    // this.dstSharedService.setClientKey('0');
    // this.dstSharedService.setTaxYearScenario('2021', '40');
    // this.dstSharedService.setS3uploadButtonAccessUsers(['400394467']);
    // this.generateTaxYears('2022', '2022');
    // this.loadMarkAsOptions();
    // this.loadGridFilters();
    // this.refresh();//false,'1416347','1416348'
    


    this.messageService.subscribe(StatementTrackerService.ID_CREATE_NEW,"show-data-loss-confirmation",(type)=>{
      this.showDataLossConfirmation.emit(type);
    });
    
    this.messageService.subscribe(StatementTrackerService.ID_CREATE_NEW,"close-data-loss-confirmed",()=>{
          this.hardDeleteStatement(this.sharedService.getFormStateArray()[0].statementId);
          
    });
    this.messageService.subscribe(StatementTrackerService.ID_CREATE_NEW,"is-child-statement-edit-parent",(payload)=>{
      this.isChildEditing.emit(payload);
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Angular Elements input is not defined on Init
    // see for details https://github.com/angular/angular/issues/29050
    // due to the above-mentioned reason, no api calls should be fired before input objects are received from gtw-ui
    if (!changes.gtwInputParam?.isFirstChange()) {
      try {
        const param = JSON.parse(changes.gtwInputParam.currentValue);
        this.dstSharedService.setClientKey(param.clientKey);
        // this.dstSharedService.setSSO(param.ssoId);
        this.dstSharedService.setTaxYearScenario(param.taxYear, param.scenario);
        this.dstSharedService.setBaseURLs(param.baseURLs);
        this.dstSharedService.setDSTUser(param.ssoId, Number.parseInt(param.roleLevel));
        this.dstSharedService.setSystemLockUnlockStatus(param.systemLockStatus === "1", Number.parseInt(param.roleLevel));
        this.dstSharedService.setS3uploadButtonAccessUsers(param.s3uploadButtonAccessUsers);
        this.generateTaxYears(param.latestTaxYear, param.taxYear);
        this.loadMarkAsOptions();
        this.dfMeDropdownComponent.loadMEsCustom(this.taxYear);
        this.loadGridFilters();
        this.refresh(false,param.statementId,param.childStatementId);

  
        if(param.dataLossConfirmedType){
          this.messageService.broadcast(StatementTrackerService.ID_CREATE_NEW,"close-data-loss-confirmed");

        }

        this.changeDetectorRef.detectChanges();
      } catch (e) {
        if (e instanceof SyntaxError) {
          console.error('JSON parse Syntax Error' + e);
        }
        this.dstSharedService.showAlert('error', 'Missing gtw input params, please contact system administrator for assistance!');
      }
    }
  }

  private prepopulateStatement(selectedStatementId: string,selectedChildStatementId: string) {
    const statementRow: DSTStatement | undefined = this.tableData.find(row => row.statementId === selectedStatementId);

    if (statementRow) {
      this.activeRow = statementRow;
      this.showViewCreate(statementRow,selectedChildStatementId);
    } else {
      this.dstSharedService.showAlert('error', 'Could not find the requested statment: ' + selectedStatementId);
    }
  }

  ngOnDestroy(): void {
    this.onDisSaveSubs?.unsubscribe();
    this.onDisCancelSubs?.unsubscribe();
    this.messageService.unsubscribe(StatementTrackerService.ID_CREATE_NEW,"show-data-loss-confirmation");
    this.messageService.unsubscribe(StatementTrackerService.ID_CREATE_NEW,"close-data-loss-confirmed");
    this.messageService.unsubscribe(StatementTrackerService.ID_CREATE_NEW,"is-child-statement-edit-parent");
    this.ngPrimeTableEle?.removeEventListener("scroll", this.onScroll);
  }

  get idCreateNew(): string {
    return StatementTrackerService.ID_CREATE_NEW;
  }

  searchg($event: any): void {
    this.table.filterGlobal($event.query, 'contains');
  }

  filterCol(event: Event, field: string, matchMode: string): void {
    this.colFiltersChanged = true;
    this.table.filter((event.target as HTMLInputElement).value, field, matchMode);
  }
  
  filterMultiSelectCol(event: any, field: string, matchMode?: string): void {
    this.colFiltersChanged = true;
    this.table.filter(event.value.map((i: DSTGridFilterVal) => i.val), field, matchMode || 'in');
  }
  
  filterLeidCol(event: any, field: string, splitString: string): void {
    this.colFiltersChanged = true;
    this.table.filter(event.value.map((i: DSTGridFilterVal) => (i.val).toString().substring(0, (i.val).toString().indexOf(splitString))), field, 'in');
  }

  handleFilter(event: any): void {
    this.totalRecords = event.filteredValue?.length || (Object.keys(this.table.filters).length === 0 ? this.tableData.length: 0);

    this.populateFilters(event.filteredValue);
  }

  populateFilters(tableData: DSTStatement[]){
    this.filters.forEach(filter=>{
      const existingLeidFilterobj = _.find(this.filtersOptions,['filterId',filter.filterId])
      
      const filterObj: DSTGridFilter = existingLeidFilterobj || filter;
  
      const filterLeids: DSTGridFilterVal[] = _.chain(tableData).uniqBy(filter.searchProp).filter(f=>f[filter.searchProp as keyof DSTStatement]).map(i=><DSTGridFilterVal>{val:filter.render(i)}).value();
      filterObj.filterValues = filterLeids;
      
      if(!existingLeidFilterobj)
        this.filtersOptions.push(filterObj);

    })
  }

  resetColumnFilters(): void {
    this.cols.forEach((item: any) => {
      item.filterUserInput = null;
    });
    this.frozenCols.forEach((item: any) => {
      item.filterUserInput = null;
    });
    this.table.reset();
    this.totalRecords = this.tableData.length;

    this.populateFilters(this.tableData);
  }

  private generateTaxYears(latest: string, current: string): void {
    this.taxYears = [{data: []}];
    const latestYear: number = Number.parseInt(latest);
    const currentYear: number = Number.parseInt(current);
    for (let i = latestYear; i >= (latestYear - 6) ; i--) {
      if (i < 2016) {
        continue;
      }

      this.taxYears[0].data.push({
        id: i,
        text: i.toString(),
        selected: i === currentYear
      });
    }
    this.taxYear = current;
  }

  showTracker(): void {
    this.trackerTabsService.activeId = StatementTrackerService.ID_TRACKER;
    this.activeTab = this.trackerTabsService.getActiveTrackerTab();
    this.statementTrackerService.refresh && this.refresh();
  }

  /**
   * Navigate to an existing tab specified by tabId, if none exists, create a new tab
   * @param tabId is statementId for existing statements or csStatementId for create requirements
   * @param formOption is the option user selected from create options modal
   * @param childFormStates is a list of DSTChildFormState objects in which each object represents a child form state
   */
  addTabOrNavigate(tabId: string, formOption?: DSTFormOption, childFormStates?: DSTChildFormState[],childStatementIdToNavigate: string = ''): void {
    if (this.tableDataLoading) {
      return;
    }

    let tab: TrackerTabState | null = this.trackerTabsService.findTrackerTabById(tabId);
    let activeChildIndex;
    // create a new tracker tab
    if (!tab) {
      tab = new TrackerTabState(tabId);
      if (tabId !== StatementTrackerService.ID_CREATE_NEW) {
        if (formOption) {
          tab.addFormState(this.userClickedRow.TAXYEAR,
            this.userClickedRow.taxYearId,
            1,
            this.userClickedRow.placeHolder ? '0' : this.userClickedRow.statementId,
            Number.parseInt(formOption.FORMID),
            formOption.FORMNAME,
            '',
            Number.parseInt(formOption.FORMTYPE),
            this.userClickedRow.cseId,
            false,
            !this.userClickedRow.placeHolder && this.userClickedRow.statementId !== '0',
            undefined, undefined, this.userClickedRow.csStatementId, 
            (this.userClickedRow.statementId === '0' && this.userClickedRow.codeSectionId === 24));
        } else {
          tab.addFormState(this.userClickedRow.TAXYEAR,
            this.userClickedRow.taxYearId,
            1,
            this.userClickedRow.placeHolder ? '0' : this.userClickedRow.statementId,
            this.userClickedRow.formId,
            this.userClickedRow.formName,
            this.userClickedRow.ATTFORMTYPEDESC!,
            this.userClickedRow.FORMTYPEID!,
            this.userClickedRow.cseId,
            false,
            !this.userClickedRow.placeHolder && this.userClickedRow.statementId !== '0',
            undefined, undefined, this.userClickedRow.csStatementId,
            (this.userClickedRow.statementId === '0' && this.userClickedRow.codeSectionId === 24));

          
          childFormStates?.forEach((child: DSTChildFormState,index) => {
            tab!.addFormState(child.TAXYEAR, child.TAXYEARID, child.RETURNPERIODID,
              child.STATEMENTID.toString(), child.FORMID, child.STATEMENTNAME, child.FORMTYPE,
              child.FORMTYPEID, child.CSEID, true, true);

              if(childStatementIdToNavigate === child.STATEMENTID.toString()){
                // this.trackerTabsService.activeId = childStatementIdToNavigate;
                activeChildIndex = index + 1;
              }
          });
        }
      }
      tab.isPlaceholderChecked = this.userClickedRow.placeHolder === 1;
      this.trackerTabsService.createTrackerTab(tab);
    }
    if (tabId === StatementTrackerService.ID_CREATE_NEW) { //reset isFiled prop when create new tab gets active
      this.dstSharedService.setIsFiled(false);
    } else {
      this.dstSharedService.setIsFiled((this.userClickedRow.IS_FILED!));
    }
    this.trackerTabsService.activeId = tabId;
    this.activeTab = this.trackerTabsService.getActiveTrackerTab();
    this.trackerTabsService.setActiveTrackerTabChildIndex(activeChildIndex || 0);
  }

  closeTab(tabId: string, forceClose: boolean = false): void {
    if((
          tabId !== this.sharedService.getActiveFormState().statementId &&
          tabId !== this.sharedService.getActiveFormState().formData?.parentstatementid?.toString()
        ) &&
         this.sharedService.getActiveIndex() > 0 &&
         !this.sharedService.getActiveFormState().isView &&
         !forceClose){
      //show confirmation popup
      this.messageService.broadcast(StatementTrackerService.ID_CREATE_NEW,"show-data-loss-confirmation","close");

      return;
    }

    this.trackerTabsService.removeTrackerTabById(tabId);
    this.activeTab = this.trackerTabsService.getActiveTrackerTab();
    if (this.activeTab?.id === StatementTrackerService.ID_CREATE_NEW) { //reset isFiled prop when create new tab gets active
      this.dstSharedService.setIsFiled(false);
    }
    if (tabId === StatementTrackerService.ID_CREATE_NEW) {
      this.createNewTabIsOpen = false;
    }
    if (!this.activeTab && this.statementTrackerService.refresh) {
      this.refresh();
    }
  }

  loadGridFilters(): void {
    // this.dstSharedService.getGridFilterOptions(32508).subscribe((filters: DSTGridFilter[]) => {
    //   console.log(filters);
    //   this.filtersOptions = filters;
    // }, (e: Error) => {
    //   console.log(e);
    //   this.dstSharedService.showAlert('error', e.message);
    // });
  }

  hideStmtIdButtonDropdown(): void {
    this.ddRow.showDropdown = false;
    this.ddRow.showExtendedMenu = false;
  }

  refresh(force: boolean = false,preloadStatementId: string = '',preloadChildStatementId: string = ''): void {
    if (!force && !this.colFiltersChanged && !this.statementTrackerService.refresh) {
      return;
    }
    this.ddRow && this.hideStmtIdButtonDropdown();
    const filtersObj = {
      parent_me_string: this.selectedMes,
      statementId: '',
      tax_year: this.taxYear,
      leId: '',
      disclosureShortName: '',
      creationSource: '',
      createdBy: '',
      projectName: ''
    };
    this.tableData = [];
    this.tableDataLoading = true;
    this.dstSharedService.getGridData(32523, filtersObj)
      .subscribe((data: DSTStatement[]) => {
        data.forEach((d: DSTStatement) => {
          if (d.is304) {
            d.formId = -192;
          }
        });
        this.tableData = data;
        this.totalRecords = this.tableData.length;

        this.populateFilters(this.tableData);
      }, (e: Error) => {
        this.tableData = [];
        console.log(e);
        this.dstSharedService.showAlert('error', e.message, 999);
      }).add(() => {
      this.colFiltersChanged = false;
      this.tableDataLoading = false;
      this.statementTrackerService.refresh = false;

      this.ngPrimeTableEle = document.querySelector('cdk-virtual-scroll-viewport.p-datatable-virtual-scrollable-body');
      this.ngPrimeTableEle?.addEventListener("scroll", this.onScroll.bind(this));

      if(preloadStatementId)
        this.prepopulateStatement(preloadStatementId,preloadChildStatementId);
    });
  }

  onScroll() : void {
    this.ddRow && this.hideStmtIdButtonDropdown(); //Event to hide stmt id button dropdown on scroll
  }

  private leidFilterValsConverter(filterVals: DSTGridFilterVal[]): DSTGridFilterVal[] {
    const result: DSTGridFilterVal[] = [];
    filterVals?.forEach((filterVal: DSTGridFilterVal) => {
      result.push({val: (filterVal.val as string).split('-')[0].trim()});
    });
    return result;
  }

  getFilterOptionByID(filterId: string): DSTGridFilterVal[] | [] {
    return this.filtersOptions.find((f: DSTGridFilter) => f.filterId === filterId)?.filterValues || [];
  }

  exportToExcel(): void {
    this.exportingExcel = true;
    const headersMap: JSONExportHeader[] = [
      {
        header: 'Tax Year',
        field: 'TAXYEAR'
      }, {
        header: 'Source',
        field: 'creationSource'
      }, {
        header: 'Creation Status',
        field: 'creationStatus'
      }, {
        header: 'Statement Id',
        field: 'statementId'
      }, {
        header: 'Statement Name',
        field: 'formName'
      }, {
        header: 'Type',
        field: 'codeSectionName'
      },{
        header: 'File Name',
        field: 'attachment_name'
      },{
        header: 'Primary Business',
        field: 'businessName'
      }, {
        header: 'Primary LEID',
        field: 'leId'
      }, {
        header: 'Primary Name',
        field: 'entityName'
      }, {
        header: 'Secondary Business',
        field: 'SECONDARY_BUSINESS'
      }, {
        header: 'Secondary LEID',
        field: 'SECONDARY_LEID'
      }, {
        header: 'Secondary Name',
        field: 'SECONDARY_ENTITY'
      }, {
        header: 'Filing Group',
        field: 'dcnName'
      }, {
        header: 'Project',
        field: 'projectName'
      }, {
        header: 'Project Steps',
        field: 'projectStepName'
      }, {
        header: 'Project BSLA',
        field: 'PROJECTBSLACODE'
      }, {
        header: 'Created By',
        field: 'createdBy'
      }, {
        header: 'Created On',
        field: 'createdOnFormatted'
      }, {
        header: 'Modified By',
        field: 'modifiedBy'
      }, {
        header: 'Modified On',
        field: 'modifiedFormatted'
      }, {
        header: 'Marked as Reviewed',
        field: 'isReviewedVal'
      }, {
        header: 'Reviewed By',
        field: 'reviewedBy'
      }, {
        header: 'Reviewed On',
        field: 'reviewedOn'
      }, {
        header: 'Election',
        field: 'ELECTIONFLAG'
      }
    ];
    const fileName: string = 'DST_StatementTracker_' + this.dstSharedService.getExcelExportDateString();

    const filtersObj = {
      parent_me_string: this.selectedMes,
      statementId: this.frozenCols[0].filterUserInput || '',
      tax_year: this.dstSharedService.getMultiSelectFilterInputAsString((this.cols[0].filterUserInput as DSTGridFilterVal[]), 'val') || this.taxYear,
      leId: this.dstSharedService.getMultiSelectFilterInputAsString((this.frozenCols[1].filterUserInput as DSTGridFilterVal[]), 'val', this.leidFilterValsConverter),
      disclosureShortName: this.dstSharedService.getMultiSelectFilterInputAsString((this.cols[2].filterUserInput as DSTGridFilterVal[]), 'val'),
      creationSource: this.dstSharedService.getMultiSelectFilterInputAsString((this.cols[3].filterUserInput as DSTGridFilterVal[]), 'val'),
      createdBy: this.cols[8].filterUserInput || '',
      projectName: this.dstSharedService.getMultiSelectFilterInputAsString((this.cols[6].filterUserInput as DSTGridFilterVal[]), 'val')
    };
    this.statementTrackerService.getDstStmtExportData(filtersObj.parent_me_string, filtersObj.statementId.toString(), filtersObj.tax_year
      , filtersObj.leId, filtersObj.disclosureShortName, filtersObj.creationSource, filtersObj.createdBy.toString(), filtersObj.projectName)
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          this.jsonExportService.exportToExcel(fileName, data, headersMap);
        } else {
          this.dstSharedService.showAlert('warning', "No Data found to Export");
        }

      }, (e: Error) => {
        console.log(e);
        this.dstSharedService.showAlert('error', e.message, 999);
      }).add(() => {
        this.exportingExcel = false;
    });
  }

  onTaxYearChanged(object: any): void {
    this.taxYear = object.change.text;
    this.dfMeDropdownComponent.loadMEsCustom(this.taxYear);
  }

  selectedMeChanged(selected: string): void {
    this.selectedMes = selected;
  }

  loadMarkAsOptions(): void {
    this.statementTrackerService.loadMarkAsOptions()
      .subscribe((response: DSTMarkAsOption[]) => {
        this.markAsOptions = response;
      });
  }

  createDisclosureNew(): void {
    this.ddRow && this.hideStmtIdButtonDropdown();
    let tab: TrackerTabState | null = this.trackerTabsService.findTrackerTabById(StatementTrackerService.ID_CREATE_NEW);
    if (!tab) {
      tab = new TrackerTabState(StatementTrackerService.ID_CREATE_NEW);
      this.trackerTabsService.createTrackerTab(tab);
      this.dstSharedService.setIsFiled(false);
    }
    this.trackerTabsService.activeId = StatementTrackerService.ID_CREATE_NEW;
    this.activeTab = this.trackerTabsService.getActiveTrackerTab();
    this.createNewTabIsOpen = this.trackerTabsService.getActiveTrackerTab()?.id === StatementTrackerService.ID_CREATE_NEW;
  }

  onActionClicked(payload: StmtBtnActionPayload): void {
    switch (payload.name) {
      case 'show-dropdown': {
        this.ddRow = payload.data.row;
        this.menuPos = payload.data.menuPos;
        break;
      }
      case 'show-view-update': {
        this.activeRow = payload.data;
        this.showViewCreate(this.activeRow,'');
        break;
      }
      case 'toggle-review': {
        this.activeRow = payload.data;
        this.toggleReview(payload.data);
        break;
      }
      case 'tagging-project': {
        this.activeRow = payload.data;
        this.onTaggingProject();
        break;
      }
      case 'copy': {
        this.activeRow = payload.data;
        this.onCopyReplicate('Copy');
        break;
      }
      case 'replicate': {
        this.activeRow = payload.data;
        this.onCopyReplicate('Replicate');
        break;
      }
      case 'deactivate': {
        this.activeRow = payload.data;
        this.showDeleteConfirmDialog();
        this.activeRow.showDropdown = false;
        break;
      }
      case 'unmatch-tracker': {
        this.activeRow = payload.data;
        this.showUnmatchConfirmDialog();
        break;
      }
      case 'mark-other-reason': {
        this.activeRow = payload.data;
        this.showMarkOtherReasonDialog();
        break;
      }
      case 'match-tracker': {
        this.activeRow = payload.data;
        this.onMatchTracker();
        break;
      }
      case 'save-success': {
        this.refresh();
        break;
      }
      default:
        return;
    }
  }

  showViewCreate(row: DSTStatement,childStatementIdToNavigate: string): void {
    this.userClickedRow = row;
    this.dstSharedService.setIsFiled((this.activeRow.IS_FILED!));
    if (row.placeHolder || row.statementId === '0') {
      // create mode
      this.dstSharedService.setTableActionStatus(true);
      this.statementTrackerService.getCreateStatements(row.cseId).subscribe((data: DSTFormOption[]) => {
        this.formOptions = data;
        if (data.length < 2) {
          this.addTabOrNavigate(row.csStatementId.toString());
        } else {
          this.showCreateOptions();
        }
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.dstSharedService.showAlert('error', 'Something went wrong while getting the create statements, please try again!');
      }).add(() => {
        this.dstSharedService.setTableActionStatus(false);
      });
    } else {
      // view mode
      this.viewDisclosure(row,childStatementIdToNavigate);
    }
  }

  private viewDisclosure(row: DSTStatement,childStatementIdToNavigate:string) {
    if (row.associatedStatements) {
      this.dstSharedService.setTableActionStatus(true);
      this.stmtIdBtnService.getChildStatements(row.statementId).subscribe((childFormStates: DSTChildFormState[]) => {
        this.addTabOrNavigate(row.statementId, undefined, childFormStates,childStatementIdToNavigate);
      }, (eMsg: string) => {
        console.log(eMsg);
        this.dstSharedService.showAlert('error', eMsg);
      }).add(() => {
        this.dstSharedService.setTableActionStatus(false);
      });
    } else {
      this.addTabOrNavigate(row.statementId);
    }
  }

  openChildDisclosure(childDisclosure: string, parentRow: DSTStatement): void {
    const childStatmentId = childDisclosure.split('-')[0].trim();
    this.dstSharedService.setTableActionStatus(true);
    this.dstSharedService.setIsFiled((parentRow.IS_FILED!));
    this.statementTrackerService.getDstStmtData(childStatmentId).subscribe((data: DSTStatement[]) => {
      console.log(data);
      if (data && data[0]) {
        this.userClickedRow = data[0];
        this.addTabOrNavigate(childStatmentId);
      }
    }, (error: HttpErrorResponse) => {
      console.log(error);
      this.dstSharedService.showAlert('error', 'Something went wrong while getting the create statements, please try again!');
    }).add(() => {
      this.dstSharedService.setTableActionStatus(false);
    });
  }

  showDropdown(thisRow: DSTStatement): void {
    this.tableDataFiltered.forEach((row: DSTStatement) => {
      row.showDropdown = false;
      row.showExtendedMenu = false;
    });
    thisRow.showDropdown = true;
  }

  toggleReview(row: DSTStatement): void {
    row.showDropdown = false;
    row.isReviewedVal = row.isReviewedVal === 1 ? 0 : 1;
    this.dstSharedService.setTableActionStatus(true);
    this.stmtIdBtnService.setReviewed(row.statementId, row.isReviewedVal)
      .subscribe((response: SaveActionResponse) => {
        if (response.callSuccess == 1) {
          this.dstSharedService.showAlert('success', 'Status change saved successfully!');
        } else {
          this.dstSharedService.showAlert('error', 'Something went wrong while saving your changes, please try again!');
        }
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.dstSharedService.showAlert('error', error.message);
      })
      .add(() => {
        this.dstSharedService.setTableActionStatus(false);
      });
  }

  onTaggingProject(): void {
    this.showProjectTaggingModal = true;
    setTimeout(() => {
      this.projectTaggingComponent.showMore('project-tagging');
    });
  }

  afterProjectTaggingModalClosed(): void {
    this.showProjectTaggingModal = false;
    this.projectTaggingComponent.closeModalOk('project-tagging');
  }

  onCopyReplicate(mode: string): void {
    this.showCopyRepModal = true;
    this.copyMode = mode;
    setTimeout(() => {
      this.copyRepComponent.showMore('copy-replicate');
    });
  }

  afterCopyRepModalClosed(): void {
    this.trackerTabsService.destroyModalTab();
    this.showCopyRepModal = false;
  }

  onMatchTracker(): void {
    this.showMatchTrackerModal = true;
    setTimeout(() => {
      this.matchTrackerComponent.showMore('match-tracker');
    });
  }

  afterMatchTrackerModalClosed(): void {
    this.showMatchTrackerModal = false;
  }

  // onActiveRowChanged(row: DSTStatement): void {
  //   this.activeRow = row;
  // }

  private showCreateOptions(): void {
    this.showOptionsModal = true;
    setTimeout(() => {
      this.createOptionsComponent.showMore('create-options');
    });
  }

  afterOptionsModalClosed(option?: DSTFormOption): void {
    if (option) {
      this.addTabOrNavigate(this.userClickedRow.csStatementId.toString(), option);
    }
    this.showOptionsModal = false;
  }

  showDeleteConfirmDialog(): void {
    this.showConfirmDeleteModal = true;
    setTimeout(() => {
      this.dstDeleteConfirmDialog.showMore('confirm-delete');
      // styling fix for gtw-ui
      const modalEle: Element | null = document.querySelector('[ng-reflect-id="confirm-delete"]');
      modalEle?.classList.add('gtw-web-components', 'dst-modal-sm');
    });
  }

  onDSTDeleteConfirmDialogOK(): void {
    // remove local statement
    // this.tableDataFiltered.splice(this.tableDataFiltered.indexOf(this.activeRow), 1);
    // this.tableData.splice(this.tableData.indexOf(this.activeRow), 1);
    this.stmtIdBtnService.delete(this.activeRow).subscribe((success: boolean) => {
      this.refresh(true);
    });
  }

  hardDeleteStatement(statementId: string): void{
    this.stmtIdBtnService.hardDeleteStatementWithChild(statementId).subscribe(suuccess=>{
      console.log("Deleted statement successfully");
      this.closeTab(this.activeTab!.id,true);
    },err=>{
      console.error("Failed to delete the statement",err);
    })
  }

  closeDSTDeleteConfirmDialog(id: string): void {
    this.dstDeleteConfirmDialog.closeModal(id);
    setTimeout(() => {
      this.showConfirmDeleteModal = false;
    });
  }

  closeDSTDeleteConfirmDialogOk(id: string): void {
    this.dstDeleteConfirmDialog.closeModalOk(id);
    setTimeout(() => {
      this.showConfirmDeleteModal = false;
    });
  }

  showMarkOtherReasonDialog(): void {
    this.markAsReason = '';
    this.showMarkOtherReasonModal = true;
    setTimeout(() => {
      this.dstMarkOtherReasonDialog.showMore('mark-other-reason');
      // styling fix for gtw-ui
      const modalEle: Element | null = document.querySelector('[ng-reflect-id="mark-other-reason"]');
      modalEle?.classList.add('gtw-web-components');
    });
  }

  onDSTMarkOtherReasonDialogOK(): void {
    // mark as other reason - reason id is 4
    this.stmtIdBtnService.markAs(this.activeRow, {REASONID: 4, REASON: this.markAsReason, REASONDESC: ''});
    setTimeout(() => {
      this.showMarkOtherReasonModal = false;
    });
  }

  closeDSTMarkOtherReasonDialog(id: string): void {
    this.dstMarkOtherReasonDialog.closeModal(id);
    setTimeout(() => {
      this.showMarkOtherReasonModal = false;
    });
  }

  closeDSTMarkOtherReasonDialogOk(id: string): void {
    this.dstMarkOtherReasonDialog.closeModalOk(id);
    setTimeout(() => {
      this.showMarkOtherReasonModal = false;
    });
  }

  showUnmatchConfirmDialog(): void {
    this.showConfirmUnMatchModal = true;
    setTimeout(() => {
      this.dstUnmatchConfirmDialog.showMore('confirm-unmatch');
      // styling fix for gtw-ui
      const modalEle: Element | null = document.querySelector('[ng-reflect-id="confirm-unmatch"]');
      modalEle?.classList.add('gtw-web-components', 'dst-modal-sm');
    });
  }

  onDSTUnmatchConfirmDialogOK(): void {
    this.stmtIdBtnService.unmatch(this.activeRow); // TODO
  }

  closeDSTUnmatchConfirmDialog(id: string): void {
    this.dstUnmatchConfirmDialog.closeModal(id);
    setTimeout(() => {
      this.showConfirmUnMatchModal = false;
    });
  }

  closeDSTUnmatchConfirmDialogOk(id: string): void {
    this.dstUnmatchConfirmDialog.closeModalOk(id);
    setTimeout(() => {
      this.showConfirmUnMatchModal = false;
    });

  }

  showPDFView(id: string, statementId: string): void {
    this.showPDFViewerModal = true;
    this.getPDFProformaParams(statementId);
    setTimeout(() => {
      this.pdfViewerModal.showMore(id);// styling fix for gtw-ui
      const modalEle: Element | null = document.querySelector('[ng-reflect-id="pdf-viewer"]');
      modalEle?.classList.add('gtw-web-components');
    });

  }

  closePDFViewerModalOk(id: string): void {
    this.pdfViewerModal.closeModalOk(id);
    setTimeout(() => {
      this.showPDFViewerModal = false;
    });
  }

  getPDFProformaParams(statementId: string): void {

    this.dstProformaData = {};
    this.stmtDetailsLoaded = false;
    this.statementTrackerService.getPDFProformaParams(statementId).subscribe((data: FormReivewPdf[]) => {
      if (data.length < 1) {
        throw Error('PDF Proforma Params not found for the statementId: ' + statementId);
      } else {
        this.dstProformaData = data[0];
        this.dstProformaData.FORM_SUB_CAT = 'DIFF_PDF'; //Default values
        this.dstProformaData.SRC_TYPE = 'DOM';
        this.dstProformaData.JOB_ID = '0';
      }
    }, (error: HttpErrorResponse) => {
      console.log(error);
      this.dstSharedService.showAlert('error', 'Something went wrong while getting statement details, please try again!');
    }).add(() => {
      this.stmtDetailsLoaded = true;
    });
  }

  onProjectTagging(): void {
    this.onActionClicked({name: 'tagging-project', data: this.ddRow});
  }

  onCopyOrReplicate(mode: string): void {
    this.onActionClicked({name: mode.toLowerCase(), data: this.ddRow});
  }

  onShowDeleteConfirm(): void {
    this.onActionClicked({name: 'deactivate', data: this.ddRow});
  }

  onShowUnmatchConfirm(): void {
    this.onActionClicked({name: 'unmatch-tracker', data: this.ddRow});
  }

  onMatchStatementTracker(): void {
    this.onActionClicked({name: 'match-tracker', data: this.ddRow});
  }

  onShowMarkOtherReason(): void {
    this.onActionClicked({name: 'mark-other-reason', data: this.ddRow});
  }

  onSaveSuccess(): void {
    this.onActionClicked({name: 'save-success', data: this.ddRow});
  }

}
